@use '../components';

.steps {
    &--container {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }
    &--parent {
        display: flex;
        align-items: center;
        justify-content: end;
        flex: 1;

        &:first-child {
            flex: 0.6;
        }
    }
    &--tail {
        background: components.$in-progress-modal-bg;
        height: 9px;
        flex: 1;

        &-active {
            background: components.$primary-color;
        }
    }
    &--head {
        width: 24px;
        height: 24px;
        background: components.$in-progress-modal-bg;
        border-radius: 100%;
        margin-left: -1px;
        border: 1px solid white;

        &-active {
            background: components.$primary-color;
        }
    }
}
