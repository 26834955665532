:export {
  yellowProgressBar: #ebcf3c;
  blueProgressBar: #2C70E3;
  trainingBgColor: #fed673;
  white: #ffffff;
  bodyBgColor: #f6f6f6;
  primaryColor: #16a34a;
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0) scaleY(1) scaleX(1);
    transform: translate(-50%, 0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-36px);
    transform: translateY(-36px);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom-up
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom-up {
  0% {
    -webkit-transform: translate(0%, 1000px);
    transform: translate(0%, 1000px);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(0%, 0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
:root {
  --gap-ratio: 0.5;
  --char-w: 0.95ch;
  --gap: calc(var(--gap-ratio) * var(--char-w));
  --n-char: 10;
  --in-w: calc(var(--n-char) * (var(--char-w) + var(--gap)));
}

.modal--parent {
  position: fixed;
  z-index: 999999999999;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.4392156863);
  backdrop-filter: blur(3px);
  transition: 0.3s all;
  animation: fade-in 0.9s cubic-bezier(0.23, 1, 0.32, 1) both;
}
.modal--body {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: #ffffff;
  min-height: 200px;
  max-height: 90vh;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px;
  animation: slide-in-blurred-bottom-up 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.modal--body-center, .modal--body-center-fix {
  top: 0;
  margin: auto;
  height: 50%;
  border-radius: 20px;
  left: 20px;
  right: 20px;
}
.modal--body-center-fix {
  height: 80%;
}
.modal--body-content {
  text-align: center;
  flex-grow: 1;
  overflow-y: auto;
}
.modal--btn-parent {
  position: absolute;
  left: 10px;
  right: 10px;
  display: flex;
  column-gap: 10px;
  bottom: 20px;
  max-width: 600px;
  margin: 0 auto;
}
.modal--cancel-btn {
  background: #e85b5b;
}
.modal--body-feedback {
  background-color: #ffffff;
  width: 80%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-height: 400px;
  max-height: 80%;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
}
.modal--body-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  row-gap: 50px;
}
.modal--close-icon {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
.modal--success-header {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
}
.modal--farmer-icon {
  display: flex;
  justify-content: center;
}
.modal--message-container {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 0 auto;
}
.modal--success-text {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
}
.modal--top-divider {
  width: 60px;
  height: 7px;
  border-radius: 5px;
  background: rgba(22, 163, 74, 0.5);
  margin: 0 auto;
}
.modal--close-icon-btn {
  display: flex;
  justify-content: flex-end;
  z-index: 2;
}
.modal--confirm-content {
  display: flex;
  flex-direction: column;
  row-gap: 4%;
  height: 100%;
}
.modal--confirm-message {
  color: #ff1515;
  font-size: 1.25rem;
  font-weight: 600;
}

.duplicate-confirm-modal > div:nth-child(1) {
  min-height: 420px;
}

.modal-bg {
  background-image: url("../../assets/modal-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

