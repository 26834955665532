:export {
  yellowProgressBar: #ebcf3c;
  blueProgressBar: #2C70E3;
  trainingBgColor: #fed673;
  white: #ffffff;
  bodyBgColor: #f6f6f6;
  primaryColor: #16a34a;
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0) scaleY(1) scaleX(1);
    transform: translate(-50%, 0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-36px);
    transform: translateY(-36px);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom-up
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom-up {
  0% {
    -webkit-transform: translate(0%, 1000px);
    transform: translate(0%, 1000px);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(0%, 0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
:root {
  --gap-ratio: 0.5;
  --char-w: 0.95ch;
  --gap: calc(var(--gap-ratio) * var(--char-w));
  --n-char: 10;
  --in-w: calc(var(--n-char) * (var(--char-w) + var(--gap)));
}

.farmer-id--parent {
  text-align: center;
}
.farmer-id--header {
  font-size: clamp(1.5rem, 2.5vw, 1.5rem);
  font-weight: 600;
}
.farmer-id--header-parent {
  margin: 30px 0px;
}
.farmer-id--video {
  width: 100%;
  height: auto;
}
.farmer-id--manual-link-parent {
  padding: 14px 16px;
  background-color: #ffffff;
  border-radius: 50px;
  border: 1px solid rgba(22, 163, 74, 0.5);
  font-size: 12px;
  font-weight: 500;
  margin-top: 20px;
}
.farmer-id--search-text {
  font-weight: 500;
  font-size: 12px;
  color: #655f5f;
}
.farmer-id--name {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-transform: capitalize;
}
.farmer-id--client-code {
  font-size: 14px;
  line-height: 21px;
}
.farmer-id--modal .modal--body-center {
  height: 79%;
}
.farmer-id--modal-icons {
  margin-top: 156px;
}
.farmer-id--section-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.farmer-id--section-icon:last-child {
  margin-top: 52px;
}
.farmer-id--single-icon {
  cursor: pointer;
  border: 1px solid #16a34a;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 25px;
}
.farmer-id--circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 20px;
  background: #d9d9d9;
}
.farmer-id--client-data {
  background: rgba(22, 163, 74, 0.15);
  position: absolute;
  left: 0;
  right: 0px;
  top: 0px;
  padding: 20px;
}

.scanner-label {
  margin-top: 20px;
}

