@use '../../scss/abstract/variables';

.card-stock {
    margin-top: 70px;

    @media (max-width: 540px) {
        margin-top: 32px;
    }

    &--row {
        max-width: 900px;
        margin: auto;
        gap: 80px 0px;

        @media (max-width: 540px) {
            gap: 0px;
        }
    }

    &--button {
        width: 100%;
        margin-top: 60px;

        @media (max-width: 540px) {
            margin-top: 8px;
            padding-right: 0px;
        }
    }

    &--single {
        @media (max-width: 540px) {
            margin-top: 27px;

            &:first-child {
                margin-top: 0px;
            }

            &:nth-child(2) {
                margin-top: 0px;
            }
        }
    }
}

.button-amount {
    font-weight: normal;
    margin-left: 10px;
}

.sale-card-parent {
    max-width: 900px;
    margin: auto;
    margin-top: 40px;

    @media (max-width: 540px) {
        margin-top: 19px;
    }

    .sale-card {
        &--header {
            margin-bottom: 16px;
            font-size: 19px;
            font-weight: 500;
            color: variables.$label-text-color;

            @media (max-width: 540px) {
                margin-bottom: 20px;
            }
        }

        .client-data {
            font-size: clamp(1rem, 2.5vw, 1.188rem);
            flex: 1;
            margin-top: 10px;

            &--name {
                font-weight: 600;
                color: variables.$label-text-color;
                text-transform: capitalize;
            }

            &--phone {
                display: flex;
                color: #16a34a;

                &-content {
                    font-weight: 600;
                }
            }
        }

        &--form-group {
            margin-bottom: 42px;

            @media (max-width: 540px) {
                margin-bottom: 37px;
            }

            &-last {
                margin-bottom: 24px;
            }
        }

        &--total {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-bottom: 32px;

            &-label {
                font-size: 16px;
                color: variables.$card-payment-color;
            }

            &-amount {
                font-size: 23px;
                font-weight: bold;
                color: variables.$label-text-color;
            }
        }
    }

    .label-card-number {
        display: flex;
        justify-content: space-between;
    }

    .card-number--amount {
        font-size: 19px;
        font-weight: bold;
        color: variables.$label-text-color;

        @media (max-width: 540px) {
            font-size: 16px;
        }
    }
}

.card-inventory-parent {
    margin-top: 40px;
}

.card-inventory {
    display: flex;
    justify-content: space-between;
    padding: 15px 0px 10px;
    border-bottom: 1px solid variables.$divide-color;

    @media (max-width: 540px) {
        padding: 14px 0px 7px;
    }

    &:last-child {
        border-bottom: 0px;
        padding-bottom: 0px;
    }

    &:first-child {
        padding-top: 0px;
    }

    &--top {
        font-size: 16px;
        color: variables.$card-payment-color;
        margin-bottom: 10px;

        @media (max-width: 540px) {
            margin-bottom: 5px;
            font-size: 14px;
        }
    }

    &--bottom {
        font-size: 18px;
        font-weight: bold;
        color: variables.$label-text-color;

        @media (max-width: 540px) {
            font-size: 16px;
        }
    }

    &--summary {
        font-size: 16px;
        color: variables.$label-text-color;
        border-bottom: 1px solid variables.$divide-color;
        padding-bottom: 15px;
    }

    &--summary-total {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0px;
        }
    }

    &--row {
        min-width: 150px;

        @media (max-width: 540px) {
            min-width: 126px;
        }

        @media (max-width: 320px) {
            min-width: auto;
        }
    }
}

.card-confirm-parent {
    max-width: 520px;
    margin: auto;

    .card-confirm--row {
        &:first-child {
            @media (max-width: 540px) {
                margin-top: 40px;
            }
        }
    }
}

.card-confirm {
    &--header {
        text-align: center;
        margin: 64px 0px 57px;
        font-size: 19px;
        color: variables.$primary-color;

        @media (max-width: 540px) {
            font-size: 16px;
            margin: 0px 0px 47px;
        }

        &-bold {
            font-weight: 600;
            margin-bottom: 8px;

            @media (max-width: 540px) {
                margin-bottom: 4px;
            }
        }
    }

    &--subheader {
        color: variables.$label-text-color;

        @media (max-width: 540px) {
            width: 304px;
            text-align: center;
            margin: auto;
        }
    }

    &--client {
        max-width: 309px;
        margin: auto;
        margin-bottom: 85px;

        &-header {
            font-size: 16px;
            font-weight: 500;
            color: variables.$card-payment-color;
            margin-bottom: 5px;
        }
    }

    &--button {
        max-width: 389px;
        margin: auto;
        font-size: 16px;
    }
}

.card-client-confirm-parent {
    min-width: 289px;
}

.selected-client-sms {
    display: flex;
    justify-content: space-between;
}

.generate-sms {
    color: variables.$white;
    font-size: clamp(1rem, 2.5vw, 1.188rem);
    text-decoration: none;
    font-weight: 600;
    width: 100%;
    height: max-content;
}

.client-code-text {
    position: relative;
    z-index: 99;
}

.dropdown {
    &-parent {
        margin-top: 0px;
        background: variables.$white;
        border: 1px solid variables.$divide-color;
        border-top: 0px;
        max-height: 160px;
        overflow: scroll;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        position: absolute;
        width: 100%;
        z-index: 99999;
    }

    &-single {
        display: flex;
        align-items: center;
        padding: 15px;
        border-bottom: 1px solid variables.$body-bg-color;

        &:last-child {
            border-bottom: 0px;
        }
    }

    &--name-abbr {
        font-size: clamp(0.75rem, 2.5vw, 0.875rem);
        color: variables.$village-card-divide;
        &-parent {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: variables.$name-abbr-bg;
            border: 1px solid variables.$village-client-border;
        }
    }

    &--name-id {
        margin-left: 10px;
    }

    &--name {
        font-weight: 600;
        color: variables.$home-card-black;
        text-transform: capitalize;
        font-size: clamp(0.875rem, 2.5vw, 1rem);
    }

    &--id {
        color: variables.$card-payment-color;
        font-size: clamp(0.75rem, 2.5vw, 0.875rem);
    }
}

.card-qr {
    &--parent {
        text-align: center;

        .scanner-label {
            margin: 20px 0px;
        }
    }
    &--header-parent {
        margin: 50px 0px 40px;
    }
    &--header {
        font-size: clamp(1rem, 2.5vw, 1.5rem);
        font-weight: 500;
        color: variables.$black;
        margin-bottom: 5px;
    }
    &--sub-header {
        font-size: clamp(0.75rem, 2.5vw, 0.875rem);
        color: variables.$label-text-color;
    }

    &--manual-link-parent {
        margin-top: 20px;
    }

    &--manual-link {
        font-size: clamp(0.875rem, 2.5vw, 1.2rem);
        color: variables.$primary-color;
    }

    &--video {
        width: 100%;
        height: 50vh;

        @media screen and (max-width: 540px) {
            height: max-content;
        }
    }
}

.scan-region-highlight {
    svg {
        stroke: variables.$primary-color !important;
    }
}

.invalid-qr-error {
    color: variables.$offline-color-text;
}

.card-payment-loading {
    &--parent {
        margin-top: 150px;
    }
    &--text {
        text-align: center;
        width: 70%;
        margin: auto;
    }
}

.payment-response {
    margin-top: 70px;

    &--success {
        background: variables.$online-color;
    }

    &--offline {
        background: variables.$village-card-divide-desktop;
    }

    &--failed {
        background: variables.$offline-color;
    }

    &--status {
        padding: 15px;
        border-radius: 8px;
        border: 1px solid variables.$thin-black;
        text-align: center;
    }

    &--details {
        margin-top: 30px;
        border: 1px solid variables.$thin-black;
        border-radius: 8px;
        padding: 15px;

        &-header {
            font-weight: 500;
            font-size: clamp(0.938rem, 2.5vw, 1.063rem);
        }

        &-body {
            margin-top: 15px;
        }

        &-section {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            font-size: clamp(0.875rem, 2.5vw, 1rem);
        }

        &-value {
            font-weight: 500;
        }
    }

    &--total {
        padding: 15px;
        border-radius: 8px;
        border: 1px solid variables.$thin-black;
        text-align: center;
        margin-top: 45px;

        &-text {
            font-weight: 500;
        }
    }

    &--close {
        position: absolute;
        top: 40px;
        right: 30px;
        font-weight: 600;
        font-family: 'Work Sans', sans-serif;
        font-size: 25px;
        color: variables.$primary-color;
        cursor: pointer;
    }

    &--icon {
        margin-bottom: 7px;
    }
}

.list {
    font-size: clamp(0.975rem, 2.5vw, 1rem);
    color: variables.$card-payment-color;
    margin-top: 40px;

    &--row {
        margin-top: 14px;
        padding: 0px 0px 7px 0px;
        border-bottom: 1px solid variables.$village-card-divide-desktop;

        &:last-child {
            border-bottom: none;
        }

        &--title {
            font-weight: 500;
            text-transform: capitalize;
        }
    }

    &--item {
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;
        align-items: center;
        margin-left: 20px;
        padding-block: 20px;
        font-size: clamp(1rem, 2.5vw, 1.125rem);

        &--header {
            font-weight: 600;
            font-size: 20px;
        }

        &--sub-header {
            font-weight: lighter;
            font-size: 14px;
        }

        &--left-side {
            margin: 0 auto;
        }

        &--center {
            margin: 0 10px;
        }

        &--right-side {
            display: flex;
            gap: 4px;
            justify-content: center;
        }
    }
}

.card-inventory {
    background-color: white;
    border-radius: 30px;

    &--list {
        margin: 40px auto 60px;
    }

    &--header {
        margin-bottom: 3vh;
    }
    &--num-cards {
        width: 45px;
        height: 45px;
        display: flex;
        background-color: #2C70E3;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
    }
    &--num-cards p {
        color: white;
        font-weight: bold;
        font-size: 20px;
    }
    &--amount {
        color: #2C70E3;
    }
}

.summary-parent-container {
    margin-right: -15px;
    margin-left: -15px;
}
