.page-spinner {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 50%;
  transform: translateY(50%);
}

.page-spin-fullscreen {
  top: 0px;
  bottom: 0px;
  display: flex;
  background: rgba(255, 255, 255, 0.68);
  z-index: 9999;
  transform: translateY(0px);
}

