:export {
  yellowProgressBar: #ebcf3c;
  blueProgressBar: #2C70E3;
  trainingBgColor: #fed673;
  white: #ffffff;
  bodyBgColor: #f6f6f6;
  primaryColor: #16a34a;
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0) scaleY(1) scaleX(1);
    transform: translate(-50%, 0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-36px);
    transform: translateY(-36px);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom-up
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom-up {
  0% {
    -webkit-transform: translate(0%, 1000px);
    transform: translate(0%, 1000px);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(0%, 0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
:root {
  --gap-ratio: 0.5;
  --char-w: 0.95ch;
  --gap: calc(var(--gap-ratio) * var(--char-w));
  --n-char: 10;
  --in-w: calc(var(--n-char) * (var(--char-w) + var(--gap)));
}

.default__btn {
  background-color: transparent;
  border: 1px solid;
  height: 45px;
  display: flex;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  cursor: pointer;
  flex-grow: 0;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
  width: 320px;
  font-family: "Work Sans", sans-serif;
  width: 100%;
  padding: 0px 16px;
}
.default__btn:disabled {
  background-color: #a6cfb5;
}

.btn_info,
.btn_warn,
.btn_light,
.btn_danger,
.btn_solid {
  border: none;
}

.btn__primary {
  background-color: #16a34a;
  color: #ffffff;
}

.btn_secondary {
  background-color: #f1fdf5;
  color: #16a34a;
  border: 0px;
}

.btn_info {
  background-color: rgba(73, 130, 205, 0.1019607843);
}

.btn_warn {
  background-color: rgba(253, 186, 22, 0.1019607843);
}

.btn_light {
  background-color: rgba(151, 151, 151, 0.1019607843);
}

.btn_danger {
  color: #ffffff;
  background-color: #ff1515;
}

.btn_solid {
  background-color: #ffffff;
}

.btn_outline {
  border-radius: 5px;
  border: 1px solid #16a34a;
  background: #ffffff;
}

.btn_outline span {
  color: #16a34a;
}

.btn-size--large {
  max-width: 471px;
}

.btn-size--small {
  min-width: 130px;
}

.btn-size--icon {
  width: max-content;
  padding: 0px;
  height: max-content;
  border: 0px;
}

.btn-size--xl {
  width: 80%;
  margin: 39px auto;
  padding-block: 50px;
  border-radius: 10px;
  font-size: 20px;
  gap: 4%;
}

.disabled-btn {
  cursor: no-drop;
}

.maroon-color-button {
  background-color: #a3743b;
  position: sticky;
  bottom: 0;
}

