:export {
  yellowProgressBar: #ebcf3c;
  blueProgressBar: #2C70E3;
  trainingBgColor: #fed673;
  white: #ffffff;
  bodyBgColor: #f6f6f6;
  primaryColor: #16a34a;
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0) scaleY(1) scaleX(1);
    transform: translate(-50%, 0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-36px);
    transform: translateY(-36px);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom-up
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom-up {
  0% {
    -webkit-transform: translate(0%, 1000px);
    transform: translate(0%, 1000px);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(0%, 0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
:root {
  --gap-ratio: 0.5;
  --char-w: 0.95ch;
  --gap: calc(var(--gap-ratio) * var(--char-w));
  --n-char: 10;
  --in-w: calc(var(--n-char) * (var(--char-w) + var(--gap)));
}

.home-main {
  margin-top: 45px;
}
@media (max-width: 420px) {
  .home-main {
    margin-top: 12px;
  }
}

.ranking-link-col {
  text-align: right;
}
@media (max-width: 540px) {
  .ranking-link-col {
    text-align: center;
  }
}

.ranking-link {
  font-size: 18px;
  font-weight: 500;
}
@media (max-width: 420px) {
  .ranking-link {
    font-size: 14px;
  }
}

.deposits-link {
  font-weight: 500;
}

.home-welcome--text {
  font-size: 28px;
  font-weight: 600;
  color: #1e1e19;
}
@media (max-width: 540px) {
  .home-welcome--text {
    font-size: 19px;
  }
}

@media (max-width: 540px) {
  .home-welcome--text-large {
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    text-align: center;
  }
}

.home-commission-card {
  margin-top: 19px;
}
@media (max-width: 420px) {
  .home-commission-card {
    margin-top: 16px;
  }
}

.card-session {
  margin: 32px 0px 16px;
}
@media (max-width: 420px) {
  .card-session {
    margin: 24px 0px 8px;
  }
}

.performance-card-parent {
  height: 100%;
}

.session-header {
  font-size: 19px;
  font-weight: 500;
  color: #1e1e19;
}
@media (max-width: 420px) {
  .session-header {
    font-size: 16px;
  }
}

.card-sale-parent {
  border-bottom: 1px solid #cbcad4;
}
.card-sale-parent .card-sale {
  display: flex;
  justify-content: space-between;
  color: #2f2f20;
}
.card-sale-parent .card-sale--header {
  font-size: 18px;
  font-weight: bold;
}
@media (max-width: 420px) {
  .card-sale-parent .card-sale--header {
    font-size: 16px;
  }
}
.card-sale-parent .card-sale--duration {
  color: #545454;
  font-weight: normal;
}
.card-sale-parent .card-progressbar {
  margin-top: 22px;
}
.card-sale-parent .progressbar-numbers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0px;
}
@media (max-width: 420px) {
  .card-sale-parent .progressbar-numbers {
    margin: 11px 0px;
  }
}
.card-sale-parent .progressbar-numbers--amount {
  font-size: 23px;
  font-weight: bold;
  color: #2f2f20;
}
@media (max-width: 420px) {
  .card-sale-parent .progressbar-numbers--amount {
    font-size: 19px;
  }
}
.card-sale-parent .progressbar-numbers--total {
  font-size: 16px;
  font-weight: bold;
  color: #545454;
}
@media (max-width: 420px) {
  .card-sale-parent .progressbar-numbers--total {
    font-size: 14px;
  }
}
.card-sale-parent .progressbar-numbers--of {
  color: #abaeb1;
}

.sales-deposit-parent {
  margin-top: 16px;
  font-size: 16px;
  color: #1e1e19;
}
@media (max-width: 420px) {
  .sales-deposit-parent {
    margin-top: 12px;
  }
}
.sales-deposit-parent .sales-deposit {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  font-size: 16px;
}
.sales-deposit-parent .sales-deposit:last-child {
  margin-bottom: 0px;
}

.client-row .client-card-parent {
  padding-left: 0px;
}
@media (max-width: 540px) {
  .client-row .client-card-parent {
    padding: 4px;
  }
}
@media (max-width: 540px) {
  .client-row {
    margin-top: 4px;
    padding: 0 11px;
  }
}

.client-card {
  color: #1e1e19;
}
@media (max-width: 320px) {
  .client-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.client-card--header {
  font-size: 16px;
  font-weight: bold;
}
.client-card--light-italic {
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
}
@media (max-width: 420px) {
  .client-card--light-italic {
    font-size: 16px;
  }
}

.second-client-card {
  margin-top: 16px;
}
@media (max-width: 540px) {
  .second-client-card {
    margin-top: 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.client-number {
  display: flex;
  align-items: baseline;
}
.client-number--num {
  font-size: 40px;
  font-weight: bold;
}
@media (max-width: 420px) {
  .client-number--num {
    font-size: 33px;
  }
}
.client-number--others {
  font-size: 18px;
  font-weight: 600;
}
@media (max-width: 420px) {
  .client-number--others {
    font-size: 14px;
  }
}
.client-number--of {
  color: #abaeb1;
}
.client-number--den {
  color: #545454;
}
.client-number--content {
  margin-right: 9px;
}
@media (max-width: 420px) {
  .client-number--content {
    margin-right: 3px;
  }
}
.client-number--highlight {
  color: #16a34a;
}

.client-contact-card {
  margin-bottom: 8px;
}

.target-list--header {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
}
.target-list--parent {
  margin-top: 25px;
  margin-bottom: 27px;
}
.target-list--card {
  background-color: #ffffff;
  padding: 19px 16px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
}
.target-list--card-canceled-duplicate {
  background-color: #dadada;
}
.target-list--card-completed {
  background-color: rgba(89, 175, 110, 0.2);
}
.target-list--card-follow-up {
  background-color: #fff1cd;
}
.target-list--card:last-child {
  margin-bottom: 0px;
}
.target-list--check-client {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.target-list--no-visit {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #c4c4c4;
}
.target-list--name {
  color: #1e1e19;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
.target-list--client-type-id {
  color: #6b6b6b;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.target-list--outcome {
  padding: 4px 15px;
  border-radius: 10px;
}
.target-list--status-phone {
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: space-between;
}
.target-list--phone {
  transform: rotateZ(108deg);
}
.target-list--outcome-text {
  color: #231f20;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}
.target-list--desired-outcome-enrolled, .target-list--desired-outcome-payment_2nd, .target-list--desired-outcome-payment_3rd, .target-list--desired-outcome-payment_finisher {
  background-color: #e4e4e7;
}
.target-list--outcome-enrolled, .target-list--outcome-card_payment, .target-list--outcome-mobile_money_payment {
  background-color: #bee9d6;
}
.target-list--outcome-follow_up {
  background-color: #ffe299;
}
.target-list--outcome-not_interested, .target-list--outcome-reimbursement_requested, .target-list--outcome-cancel_package, .target-list--outcome-duplicate {
  background-color: #fcd2cc;
}
.target-list--spinner-parent {
  margin-top: 40px;
}
.target-list--card-session {
  width: 100%;
}
.target-list--search-input {
  height: 48px;
  border-radius: 40px;
  padding-top: 13px;
  padding-bottom: 13px;
  border-color: rgba(22, 163, 74, 0.5019607843);
}

.target--filter-parent {
  display: flex;
  gap: 15px;
  margin-top: 26px;
  justify-content: center;
}
.target--filter-button {
  padding: 7px 5px;
  border-radius: 11px;
  text-align: center;
}
.target--filter-button-text {
  color: #000000;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
}
.target--filter-button-name {
  font-weight: 400;
  color: #b6b5b5;
  font-size: 12px;
  text-align: center;
  font-style: normal;
  line-height: 18px;
}
.target--filter-button-name--selected {
  color: #16a34a;
}

.search-input-container {
  margin-block: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

