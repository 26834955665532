:export {
  yellowProgressBar: #ebcf3c;
  blueProgressBar: #2C70E3;
  trainingBgColor: #fed673;
  white: #ffffff;
  bodyBgColor: #f6f6f6;
  primaryColor: #16a34a;
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0) scaleY(1) scaleX(1);
    transform: translate(-50%, 0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-36px);
    transform: translateY(-36px);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom-up
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom-up {
  0% {
    -webkit-transform: translate(0%, 1000px);
    transform: translate(0%, 1000px);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(0%, 0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
:root {
  --gap-ratio: 0.5;
  --char-w: 0.95ch;
  --gap: calc(var(--gap-ratio) * var(--char-w));
  --n-char: 10;
  --in-w: calc(var(--n-char) * (var(--char-w) + var(--gap)));
}

.alert-parent {
  display: flex;
  justify-content: center;
  animation: slide-in-blurred-top 0.6s cubic-bezier(0.23, 1, 0.32, 1) -20ms both;
}

.alert-parent--floating {
  position: fixed;
  width: 90%;
  bottom: 84px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 12px 27px;
  border-radius: 8px;
  box-shadow: 0 2px 15px 5px rgba(133, 124, 124, 0.12), 0 4px 12px 0 rgba(0, 0, 0, 0.15);
  max-width: 794px;
  animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
  z-index: 99999;
}

.alert--error {
  background: #f9ebe9;
}

.alert--success {
  background: #ebfef5;
}

.alert-default {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  display: flex;
  color: #001500;
  padding: 7.5px 0px;
  font-family: "Work Sans", sans-serif;
}
@media (max-width: 540px) {
  .alert-default {
    font-size: 12px;
    padding: 3px 0px;
  }
}
@media (max-width: 400px) {
  .alert-default {
    font-size: 11px;
  }
}

.alert-floating {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
}

.alert-floating--success {
  color: #16a34a;
}

.alert-floating--error {
  color: #e85b5b;
}

.alert-icon-parent {
  display: flex;
  margin-right: 6px;
}

.alert-icon-status {
  margin-left: 4px;
  font-weight: 500;
}
@media (max-width: 400px) {
  .alert-icon-status {
    margin-left: 1px;
  }
}

.alert-icon-status--error {
  color: #e85b5b;
}

.alert-icon-status--success {
  color: #16a34a;
}

.alert-content {
  font-weight: 500;
}
@media (max-width: 320px) {
  .alert-content {
    font-size: 10px;
  }
}

.alert-extended {
  display: flex;
  justify-content: center;
  font-weight: 500;
  text-align: center;
  width: 80%;
  margin: auto;
}
@media (max-width: 320px) {
  .alert-extended {
    font-size: 10px;
  }
}

.prefix-text {
  font-weight: 500;
  margin-right: 10px;
}
@media (max-width: 400px) {
  .prefix-text {
    margin-right: 3px;
  }
}
.prefix-text--success {
  color: #16a34a;
}
.prefix-text--error {
  color: #e85b5b;
}

