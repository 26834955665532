:export {
  yellowProgressBar: #ebcf3c;
  blueProgressBar: #2C70E3;
  trainingBgColor: #fed673;
  white: #ffffff;
  bodyBgColor: #f6f6f6;
  primaryColor: #16a34a;
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0) scaleY(1) scaleX(1);
    transform: translate(-50%, 0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-36px);
    transform: translateY(-36px);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom-up
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom-up {
  0% {
    -webkit-transform: translate(0%, 1000px);
    transform: translate(0%, 1000px);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(0%, 0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
:root {
  --gap-ratio: 0.5;
  --char-w: 0.95ch;
  --gap: calc(var(--gap-ratio) * var(--char-w));
  --n-char: 10;
  --in-w: calc(var(--n-char) * (var(--char-w) + var(--gap)));
}

:export {
  yellowProgressBar: #ebcf3c;
  blueProgressBar: #2C70E3;
  trainingBgColor: #fed673;
  white: #ffffff;
  bodyBgColor: #f6f6f6;
  primaryColor: #16a34a;
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0) scaleY(1) scaleX(1);
    transform: translate(-50%, 0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-36px);
    transform: translateY(-36px);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom-up
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom-up {
  0% {
    -webkit-transform: translate(0%, 1000px);
    transform: translate(0%, 1000px);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(0%, 0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
:root {
  --gap-ratio: 0.5;
  --char-w: 0.95ch;
  --gap: calc(var(--gap-ratio) * var(--char-w));
  --n-char: 10;
  --in-w: calc(var(--n-char) * (var(--char-w) + var(--gap)));
}

.enrollment-container {
  padding-top: 15px;
  width: 100%;
  overflow: hidden;
}

.enrollment-form, .enrollment-form--modify-package {
  display: flex;
  justify-content: space-between;
  width: 300%;
}
.enrollment-form--modify-package {
  width: 200%;
}

.enrollment-step {
  width: 100%;
  flex-shrink: 1;
  margin-right: 1%;
  height: fit-content;
}

.enrollment-step-container {
  margin-top: 20px;
}

.enrollment-button, .enrollment-button--packages {
  width: 100%;
  margin-inline: auto;
  margin-block: 15px;
}

.enrollment-button-package-selection {
  width: 90%;
  margin: auto;
  margin-left: 5%;
  margin-top: 15px;
}
@media (max-width: 420px) {
  .enrollment-button-package-selection {
    width: 95%;
  }
}

.product-selection-container {
  margin-left: 5%;
}
@media (max-width: 380px) {
  .product-selection-container {
    margin-left: -3%;
  }
}

.question-form-vertical {
  margin-top: 5px;
  margin-bottom: 10px;
}

.question-form-horizontal {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.enrollment-checkbox {
  display: flex;
}

.enrollment-packet-summary {
  display: flex;
}

.enrollment-summary-cards {
  margin-top: 16px;
}

input:invalid {
  background-color: ivory;
  border: 2px solid #e85b5b;
}

select:invalid {
  background-color: ivory;
  border: 2px solid #e85b5b;
}

.enrollment-packet-error {
  background-color: #f9ebe9;
  border-radius: 4px;
  border: 2px solid #e85b5b;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden {
  display: none;
}

.enrollment-transition-left-2 {
  animation: transition-left-2 1s forwards;
}

.enrollment-transition-right-1 {
  animation: transition-right-1 1s forwards;
}

.enrollment-slide-in-right {
  width: 100%;
  flex-shrink: 1;
  animation: slide-in-right 1s forwards;
}

.enrollment-slide-in-left {
  width: 100%;
  flex-shrink: 1;
  animation: slide-in-left 1s forwards;
}

.enrollment-slide-left-2 {
  animation: slide-left-2 1s forwards;
}

.enrollment-slide-left-3 {
  animation: slide-left-3 1s forwards;
}

.enrollment-slide-right-2 {
  animation: slide-right-2 1s forwards;
}

.enrollment-slide-right-1 {
  animation: slide-right-1 1s forwards;
}

.enrollment-slide-out-right {
  width: 100%;
  flex-shrink: 1;
  animation: slide-out-right 1s forwards;
}

.enrollment-slide-out-left {
  width: 100%;
  flex-shrink: 1;
  animation: slide-out-left 1s forwards;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.enrollment-subtitle {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.total-to-pay-bold {
  font-weight: bold;
  font-size: 20px;
}

.centered-error-message {
  margin-top: 30px;
  text-align: center;
  font-size: 25px;
}

.enrollment--client-details {
  margin-top: 15px;
  padding-bottom: 100px;
}
.enrollment--phone-title {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.enrollment--phone-icon {
  display: flex;
  align-items: center;
  height: 35px;
}

.btn-font-size-smaller {
  font-size: 0.8rem;
}

.enrollment-button, .enrollment-button--packages {
  display: flex;
  flex-direction: column;
  gap: 20vh;
}
.enrollment-button--packages {
  gap: 30px;
}
.enrollment-button .splide__slide__row, .enrollment-button--packages .splide__slide__row {
  min-height: 140px;
}

.enrollment--input .enrollment--input-field {
  border: 0px;
  background: none;
  border-bottom: 2px solid #231f20;
  border-radius: 0px;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  padding: 0px;
}
.enrollment--input .enrollment--input-field::placeholder {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: #d9d9d9;
}
.enrollment--input-error {
  font-size: 12px;
  text-align: center;
  margin: 0 auto;
  margin-top: 10px;
  max-width: 250px;
  color: #ff1515;
}
.enrollment--input-phone .enrollment--input-number {
  font-size: 38px;
}
.enrollment--input-label {
  font-size: 22px;
  font-weight: 600;
  line-height: 36px;
  margin-bottom: 50px;
}
.enrollment--topBar {
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  background: none;
}
.enrollment--step-title {
  font-size: 22px;
  font-weight: 600;
  line-height: 36px;
  margin-bottom: 20px;
}
.enrollment--top-header {
  background: none;
}

.owner-selection--parent {
  display: flex;
  gap: 18px;
  justify-content: center;
}
.owner-selection--card {
  width: 100%;
  max-width: 100px;
  height: 100px;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.owner-selection--text {
  font-weight: 600;
  line-height: 21px;
  font-size: 14px;
}

.other-owner--card {
  width: 100%;
  max-width: 80px;
  height: 60px;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.other-owner--card-parent {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.other-owner--text {
  font-weight: 600;
  line-height: 21px;
  font-size: 14px;
}
.other-owner--cards {
  display: flex;
  gap: 14px;
  justify-content: center;
  margin-bottom: 9px;
}
.other-owner--check {
  width: 16px;
  height: 16px;
  background: #186d2a;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}
.other-owner--check-card {
  display: flex;
  align-items: center;
  background: rgba(22, 163, 74, 0.5);
  width: 80px;
  justify-content: space-around;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 30px;
}
.other-owner--check-card .other-owner--text {
  color: #ffffff;
}

.selection--card-selected {
  border: 2px solid #16a34a;
  background-color: rgba(22, 163, 74, 0.2);
}

.number-owner--header {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  margin: 40px 0px;
  text-align: left;
  width: 200px;
}
.number-owner--button {
  padding: 35px 0px;
  margin-top: 40px;
  font-size: 20px;
}

.client-details--container {
  margin: 21px 0 0;
}
.client-details--base {
  margin-top: 46px;
}
.client-details--bottom-button {
  padding: 35px 0px;
  font-size: clamp(1rem, 2vw + 1rem, 1.25rem);
  margin-bottom: 30px;
}
.client-details--modal {
  background: #d6f0db;
}
.client-details--success-modal-header {
  margin-bottom: 55px;
}
.client-details--success-modal-title {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}
.client-details--success-modal-icon {
  display: flex;
  justify-content: center;
  margin-bottom: 47px;
}
.client-details--success-modal-message {
  width: 200px;
  margin: 0 auto;
  font-weight: 600;
  line-height: 18px;
  font-size: 13px;
}
.client-details--success-modal-text {
  display: flex;
  align-items: center;
  padding: 20px;
}

.adjusted-margin {
  margin-bottom: 11px;
}

.product--selection-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
}
.product--selection-header {
  margin: 30px 0px;
}

.package-selection-footer {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}

.package-selection-pay-btn {
  width: 45%;
}

.other-packet-btn {
  background-color: white;
  width: 45%;
  border: 1px solid #16a34a;
  color: black;
}

.enrollment-base--flow-client-registration .steps--tail-active {
  background: #a96778;
}
.enrollment-base--flow-client-registration .steps--head-active {
  background: #a96778;
}
.enrollment-base--flow-client-registration .client-details--bottom-button {
  background: #a96778;
}
.enrollment-base--flow-client-registration .enrollment--phone-icon svg {
  fill: #a96778;
}
.enrollment-base--flow-client-registration .enrollment--phone-icon svg path {
  stroke: #a96778;
}
.enrollment-base--flow-client-registration .enrollment--input-number {
  background: repeating-linear-gradient(90deg, #a96778 0, #a96778 var(--char-w), transparent 0, transparent calc(var(--char-w) + var(--gap))) 0 100%/calc(var(--in-w) - var(--gap)) 2px no-repeat;
}
.enrollment-base--flow-client-modification .steps--tail-active {
  background: #a3743b;
}
.enrollment-base--flow-client-modification .steps--head-active {
  background: #a3743b;
}
.enrollment-base--flow-client-modification .client-details--bottom-button {
  background: #a3743b;
}
.enrollment-base--flow-client-modification .enrollment--phone-icon svg {
  fill: #a3743b;
}
.enrollment-base--flow-client-modification .enrollment--phone-icon svg path {
  stroke: #a3743b;
}
.enrollment-base--flow-client-modification .enrollment--input-number {
  background: repeating-linear-gradient(90deg, #a3743b 0, #a3743b var(--char-w), transparent 0, transparent calc(var(--char-w) + var(--gap))) 0 100%/calc(var(--in-w) - var(--gap)) 2px no-repeat;
}
.enrollment-base--container {
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  height: 90vh;
}
.enrollment-base--no-packages-btn {
  width: 70%;
  margin: 30px auto;
}
.enrollment-base--no-packages-btn button {
  background-color: #a96778;
}
.enrollment-base--step-content {
  margin-block: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.enrollment-base--continue-btn {
  margin-top: auto;
  margin-bottom: 10%;
}

.age-form-container {
  display: flex;
  width: 200px;
  height: 70px;
  padding: 0px 10px 0px 20px;
  justify-content: space-between;
  flex-shrink: 0;
  border-radius: 10px;
  background: #d9d9d9;
  align-items: center;
  margin: 10vh auto;
}

.age-form-container-placement {
  margin-bottom: 0;
  border-radius: 10px 10px 0 0;
}

.age-form-container-selected {
  border: 1px solid #a3743b;
  background: #ffffff;
}

.age-item-container {
  display: flex;
  height: 250px;
  width: 200px;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #d9d9d9;
  background: #ffffff;
  margin: 0 auto;
  overflow-y: scroll;
}

.age-item {
  font-size: 40px;
  font-weight: 600;
  line-height: 100%;
  width: 90%;
  text-align: center;
}

.gender-icon-container {
  display: flex;
  width: 100%;
  gap: 52px;
  justify-content: center;
  margin: 15vh auto;
}

.gender-icon, .gender-icon-img-selected {
  background: #d9d9d9;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gender-icon-img {
  border-radius: 50%;
}

.gender-icon-img-selected {
  border: 4px solid #a3743b;
  box-shadow: 1px 5px 15px 0px rgba(151, 151, 151, 0.1019607843);
  background-color: #ffffff;
}

.gender-selected-text {
  color: #a3743b;
}

.form-title {
  display: flex;
  align-items: center;
}

.group-role-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
}

.group-role {
  display: flex;
  gap: 16px;
}
.group-role--icon-text, .group-role--selected {
  display: flex;
  width: 135px;
  height: 140px;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  flex-direction: column;
  border-radius: 5px;
  background: #dadadb;
}
.group-role--icon-text p, .group-role--selected p {
  text-align: center;
}
.group-role--selected {
  border-radius: 5px;
  border: 2px solid #a3743b;
  background: rgba(163, 116, 59, 0.2);
  box-shadow: 1px 4px 250px 0px rgba(151, 151, 151, 0.2509803922);
}

.no-group-role {
  width: 94%;
  margin: 0 auto;
}

