:export {
  yellowProgressBar: #ebcf3c;
  blueProgressBar: #2C70E3;
  trainingBgColor: #fed673;
  white: #ffffff;
  bodyBgColor: #f6f6f6;
  primaryColor: #16a34a;
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0) scaleY(1) scaleX(1);
    transform: translate(-50%, 0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-36px);
    transform: translateY(-36px);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom-up
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom-up {
  0% {
    -webkit-transform: translate(0%, 1000px);
    transform: translate(0%, 1000px);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(0%, 0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
:root {
  --gap-ratio: 0.5;
  --char-w: 0.95ch;
  --gap: calc(var(--gap-ratio) * var(--char-w));
  --n-char: 10;
  --in-w: calc(var(--n-char) * (var(--char-w) + var(--gap)));
}

.goal-items-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 10px;
  justify-items: center;
}

.goal-item-center {
  grid-column-start: 1;
  grid-column-end: 3;
}

.client-goal-item-container {
  width: 85px;
  height: 85px;
  background-color: #ffffff;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.client-goal-item-borders {
  border-radius: 50%;
  border-width: 4px;
  border-style: solid;
  width: 74px;
  height: 74px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: #d0eddb;
}

.client-goal-item-img {
  border-radius: 50%;
  width: 65px;
  height: 65px;
  z-index: 1;
}

.client-goal-item-name {
  position: absolute;
  top: 10px;
  left: 4px;
  font-size: 12px;
  font-family: "Work Sans", sans-serif;
  text-align: center;
  width: 60px;
  height: 40px;
  overflow: hidden;
  z-index: 0;
}

.client-goal-item-units {
  width: 24px;
  height: 24px;
  color: #ffffff;
  position: absolute;
  top: -2px;
  right: -2px;
  border-radius: 30px;
  font-size: 14px;
  display: flex;
  font-weight: 600;
  font-family: "Work Sans", sans-serif;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.client-goal-item-price {
  font-size: 13px;
  font-weight: 600;
  font-family: "Work Sans", sans-serif;
}

.client-goal-items-banner, .client-goal-items-banner--finisher, .client-goal-items-banner--delivered {
  background-color: #e4e4e7;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #e85b5b;
  padding: 7px 30px;
  width: 100%;
}

.client-goal-items-banner--delivered {
  background-color: rgba(129, 210, 25, 0.2);
}

.client-goal-items-banner--finisher {
  background-color: rgba(146, 99, 208, 0.2);
}

