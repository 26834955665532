:export {
  yellowProgressBar: #ebcf3c;
  blueProgressBar: #2C70E3;
  trainingBgColor: #fed673;
  white: #ffffff;
  bodyBgColor: #f6f6f6;
  primaryColor: #16a34a;
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0) scaleY(1) scaleX(1);
    transform: translate(-50%, 0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-36px);
    transform: translateY(-36px);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom-up
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom-up {
  0% {
    -webkit-transform: translate(0%, 1000px);
    transform: translate(0%, 1000px);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(0%, 0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
:root {
  --gap-ratio: 0.5;
  --char-w: 0.95ch;
  --gap: calc(var(--gap-ratio) * var(--char-w));
  --n-char: 10;
  --in-w: calc(var(--n-char) * (var(--char-w) + var(--gap)));
}

.visit-form-button {
  width: 100%;
  margin: auto;
  margin-top: 15px;
}
@media (max-width: 420px) {
  .visit-form-button {
    margin-bottom: 70px;
  }
}

