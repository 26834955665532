:export {
  yellowProgressBar: #ebcf3c;
  blueProgressBar: #2C70E3;
  trainingBgColor: #fed673;
  white: #ffffff;
  bodyBgColor: #f6f6f6;
  primaryColor: #16a34a;
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0) scaleY(1) scaleX(1);
    transform: translate(-50%, 0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-36px);
    transform: translateY(-36px);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom-up
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom-up {
  0% {
    -webkit-transform: translate(0%, 1000px);
    transform: translate(0%, 1000px);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(0%, 0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
:root {
  --gap-ratio: 0.5;
  --char-w: 0.95ch;
  --gap: calc(var(--gap-ratio) * var(--char-w));
  --n-char: 10;
  --in-w: calc(var(--n-char) * (var(--char-w) + var(--gap)));
}

.tab-primary {
  box-shadow: none;
  background-color: transparent;
  display: flex;
  justify-content: space-around;
}
.tab-primary::after {
  content: "";
  position: absolute;
  height: 2px;
  left: 15px;
  right: 15px;
  bottom: 0px;
  border-bottom: 1px solid #e1e1e1;
  z-index: -1;
}
@media (max-width: 540px) {
  .tab-primary {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
}

.tab-pos::before {
  content: "";
  position: absolute;
  height: 2px;
  background: #16a34a;
  transition: 0.5s all;
  bottom: 0px;
}
.tab-pos-0::before {
  left: 0%;
  right: 50%;
}
.tab-pos-1::before {
  left: 50%;
  right: 0%;
}

.tab-child-primary {
  padding-top: 40px;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #2f2f20;
  width: 180px;
  text-align: center;
  cursor: pointer;
  flex: 1;
}
@media (max-width: 540px) {
  .tab-child-primary {
    padding-top: 22px;
    font-size: 14px;
  }
}

.tab-child-link {
  margin-bottom: 6px;
}
@media (max-width: 540px) {
  .tab-child-link {
    margin-bottom: 9px;
  }
}

.tab-child-primary--active {
  color: #16a34a;
}
.tab-child-primary--active .tab-child-link {
  font-weight: 600;
}

.tab-child-body {
  position: absolute;
  left: 0px;
  right: 0px;
  margin-top: 22px;
  text-align: initial;
}
@media (max-width: 540px) {
  .tab-child-body {
    margin-top: 21px;
  }
}

