:export {
  yellowProgressBar: #ebcf3c;
  blueProgressBar: #2C70E3;
  trainingBgColor: #fed673;
  white: #ffffff;
  bodyBgColor: #f6f6f6;
  primaryColor: #16a34a;
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0) scaleY(1) scaleX(1);
    transform: translate(-50%, 0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-36px);
    transform: translateY(-36px);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom-up
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom-up {
  0% {
    -webkit-transform: translate(0%, 1000px);
    transform: translate(0%, 1000px);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(0%, 0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
:root {
  --gap-ratio: 0.5;
  --char-w: 0.95ch;
  --gap: calc(var(--gap-ratio) * var(--char-w));
  --n-char: 10;
  --in-w: calc(var(--n-char) * (var(--char-w) + var(--gap)));
}

.toggle-parent {
  width: 100%;
}

.toggle-input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.toggle-input:focus {
  outline: none;
}

.toggle-label {
  cursor: pointer;
  text-indent: 35px;
  width: 100%;
  height: 40px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
  transition: 0.35s;
}

.toggle-label:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 100%;
  transition: 0.3s;
}

.toggle-input:checked + .toggle-label {
  text-indent: calc(100% - 90px);
}

.toggle-input:checked + .toggle-label:after {
  left: calc(100% - 5px);
  top: 50%;
  transform: translate(-100%, -50%);
}

.toggle-label:active:after {
  width: 35px;
}

.toggle-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 9px;
  right: 0px;
  overflow: hidden;
  color: #ffffff;
  font-size: clamp(0.875rem, 2.5vw, 1rem);
}
@media (max-width: 420px) {
  .toggle-text {
    left: 12px;
  }
}

.toggle-input:checked + .toggle-label .toggle-text {
  right: 38px;
}
@media (max-width: 420px) {
  .toggle-input:checked + .toggle-label .toggle-text {
    right: 30px;
  }
}

.toggle-training-on label::after {
  background-color: #fed673;
}

.toggle-training-off label::after {
  background-color: #16a34a;
}

.toggle-training-on label {
  background: white;
}

.toggle-training-off label {
  background: #d9d9d9;
}

.toggle-training-on p {
  color: #fed673;
}

.toggle-training-off p {
  color: #16a34a;
}

