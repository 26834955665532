:export {
  yellowProgressBar: #ebcf3c;
  blueProgressBar: #2C70E3;
  trainingBgColor: #fed673;
  white: #ffffff;
  bodyBgColor: #f6f6f6;
  primaryColor: #16a34a;
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0) scaleY(1) scaleX(1);
    transform: translate(-50%, 0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-36px);
    transform: translateY(-36px);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom-up
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom-up {
  0% {
    -webkit-transform: translate(0%, 1000px);
    transform: translate(0%, 1000px);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(0%, 0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
:root {
  --gap-ratio: 0.5;
  --char-w: 0.95ch;
  --gap: calc(var(--gap-ratio) * var(--char-w));
  --n-char: 10;
  --in-w: calc(var(--n-char) * (var(--char-w) + var(--gap)));
}

.dual-village {
  width: 800px;
  margin: auto;
}
@media (max-width: 540px) {
  .dual-village {
    width: auto;
  }
}
.dual-village--section {
  margin-bottom: 22px;
}
@media (max-width: 540px) {
  .dual-village--section {
    margin-bottom: 9px;
  }
}
.dual-village--header-parent {
  margin-top: 40px;
  margin-bottom: 16px;
}
@media (max-width: 540px) {
  .dual-village--header-parent {
    margin-top: 24px;
    margin-bottom: 8px;
  }
}
.dual-village--header {
  font-size: 19px;
  color: #1e1e19;
}
@media (max-width: 540px) {
  .dual-village--header {
    font-size: 16px;
  }
}

.village-client-details-parent {
  margin-top: 40px;
}
@media (max-width: 540px) {
  .village-client-details-parent {
    margin-top: 1px;
  }
}

@media (max-width: 540px) {
  .village-client-packet-parent {
    margin-top: 16px;
  }
}

.village-client-details-padding {
  padding: 4px 8px;
}
@media (max-width: 540px) {
  .village-client-details-padding {
    padding: 0px;
  }
}

.client-packet {
  font-family: "Work Sans", sans-serif;
}
.client-packet--parent {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 18px;
}
.client-packet--parent:last-child {
  border-bottom: 0px;
  padding-bottom: 0px;
}
.client-packet--header {
  color: #231f20;
  font-size: 32px;
  font-weight: 600;
  line-height: 150%;
  text-align: center;
  margin-top: 20px;
}
.client-packet--body {
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
}
.client-packet--main {
  flex: 0;
  font-size: clamp(1rem, 2.5vw, 1.188rem);
  color: #1e1e19;
}
.client-packet--size-price {
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #1e1e19;
}
.client-packet--size {
  font-size: clamp(0.875rem, 2.5vw, 1rem);
}
.client-packet--price {
  font-size: clamp(1rem, 2.5vw, 1.188rem);
  font-weight: 500;
}
.client-packet--btn-parent {
  max-width: 312px;
  margin: 0 auto;
}
.client-packet--delivery-btn {
  height: 40px;
  margin-top: 24px;
}
.client-packet--empty {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 20px;
}
.client-packet--no-packet {
  display: flex;
  align-items: center;
  border-radius: 16px;
  background: #fff;
  box-shadow: 3.372px 3.372px 8.431px 0px rgba(0, 0, 0, 0.1);
  padding: 40px;
  justify-content: center;
  width: 220px;
  text-align: center;
}

.village-client-packet-header {
  color: #231f20;
  font-size: 32px;
  font-weight: 600;
  line-height: 150%;
  margin-top: 20px;
  text-align: center;
}

.village-client-payment-card {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}
.village-client-payment-card .card-link {
  text-decoration: none;
}

.village-client-sell-card {
  min-width: 312px;
  margin-bottom: 27px;
  display: flex;
  column-gap: 8px;
  flex-wrap: wrap;
}
@media (max-width: 540px) {
  .village-client-sell-card {
    margin-top: 24px;
    margin-bottom: 0px;
  }
}
@media (max-width: 320px) {
  .village-client-sell-card {
    min-width: auto;
    width: 100%;
  }
}
.village-client-sell-card .client-detail-card-sale-btn {
  height: 40px;
}

.remove-padding {
  padding-left: 0px;
  padding-right: 0px;
}

.village-client--body {
  margin-top: 24px;
}
@media (max-width: 540px) {
  .village-client--body {
    font-size: 16px;
  }
}
.village-client--body .client-contact-card {
  transform: scale(1);
  transition: 0.3s all ease-out;
}
.village-client--body .client-contact-card:hover {
  transform: scale(1.02);
  transition: 0.15s all ease-in;
  background-color: #e4e4e7;
}
.village-client--body .client-contact-card-finisher {
  transform: scale(1);
  transition: 0.3s all ease-out;
}
.village-client--body .client-contact-card-finisher:hover {
  transform: scale(1.02);
  transition: 0.15s all ease-in;
  background-color: #e4e4e7;
}
.village-client--list {
  margin-bottom: 48px;
}
.village-client--list .client-contact-card-finisher {
  background-color: #ebfef5;
  border-bottom: 1px solid #e0e0e0;
}
@media (max-width: 540px) {
  .village-client--list {
    border: solid 1px #e4e4e7;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 19px;
  }
  .village-client--list > .client-contact-card {
    margin-bottom: 2px;
    border-radius: 0px;
  }
  .village-client--list > .client-contact-card:last-child {
    margin-bottom: 0px;
  }
  .village-client--list > .client-contact-card-finisher {
    margin-bottom: 0px;
    border-radius: 0px;
  }
}
.village-client--list:last-child {
  margin-bottom: 0px;
}

.village-card {
  cursor: pointer;
}

.client-payment-parent {
  margin-top: 40px;
}
@media (max-width: 540px) {
  .client-payment-parent {
    margin-top: 16px;
  }
}

.client-payment {
  font-family: "Work Sans", sans-serif;
}
.client-payment--header {
  color: #231f20;
  font-size: 32px;
  font-weight: 600;
  line-height: 150%;
  text-align: center;
  margin-top: 20px;
}
.client-payment--no-payments {
  color: #231f20;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  text-align: center;
  width: 50%;
  padding: 20px;
  margin: 0 auto 40px auto;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}
.client-payment--progress-container {
  margin: 20px 0;
  font-family: "Work Sans", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.client-payment--progress-header {
  font-size: 24px;
  font-weight: 600;
  line-height: 150%;
  color: #979797;
  padding: 5px 0;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.client-payment--progress-goal, .client-payment--progress-not-available {
  color: #979797;
}
.client-payment--progress-goal svg, .client-payment--progress-not-available svg {
  margin-right: 10px;
}
.client-payment--progress-insufficient {
  color: #e85b5b;
}
.client-payment--progress-insufficient svg {
  margin-left: 10px;
  transform: rotate(180deg);
}
.client-payment--progress-negative-balance {
  color: #e85b5b;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}
.client-payment--progress-negative-balance svg {
  transform: rotate(180deg);
  margin-bottom: 5px;
}
.client-payment--progress-surplus {
  color: #16a34a;
}
.client-payment--progress-surplus svg {
  margin-left: 10px;
}
.client-payment--progress-finished {
  color: #9263d0;
}
.client-payment--progress-bar-not-available {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 20px;
}
.client-payment--history-parent {
  font-size: clamp(0.975rem, 2.5vw, 1rem);
  color: #636363;
}
.client-payment--history-section {
  margin-top: 14px;
  padding: 0px 0px 7px 0px;
  border-bottom: 1px solid #e1e1e1;
}
.client-payment--history-section:last-child {
  border-bottom: none;
}

.payment--amount-card-id {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  align-items: center;
  margin-left: 20px;
  padding-block: 20px;
  font-size: clamp(1rem, 2.5vw, 1.125rem);
}
.payment--content {
  font-weight: 600;
  font-size: 18px;
}
.payment--amount {
  color: #16a34a;
}
.payment--deducted-amount {
  color: #ff1515;
}
.payment--card-id {
  font-weight: lighter;
  font-size: 14px;
}
.payment--date {
  font-weight: 500;
}
.payment--secondary-icons {
  display: flex;
  gap: 4px;
}

.village-client-payment-btn {
  margin-top: 50px;
}
@media (max-width: 540px) {
  .village-client-payment-btn {
    margin-top: 24px;
  }
}

.client-payment-history--header {
  text-transform: capitalize;
}

.village-prev--client-card-details .showMore {
  max-height: 100%;
}

.no-village--parent {
  color: #e85b5b;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.no-village--parent svg {
  width: 30px;
  height: 30px;
}
.no-village--text {
  font-size: clamp(1.125rem, 2.5vw, 1.25rem);
  width: max-content;
}

.card-delivery--delivered {
  color: #16a34a;
}
.card-delivery--pre-certified {
  color: #ebcf3c;
}
.card-delivery--status {
  font-size: clamp(0.75rem, 2.5vw, 0.875rem);
}

.client-details--header {
  display: flex;
  justify-content: space-between;
}

.client-details--content--link {
  color: #16a34a;
  font-size: clamp(0.87rem, 2.5vw, 1rem);
  margin-left: 5px;
  text-decoration: underline;
}

.client-details--link {
  display: grid;
  grid-template-columns: repeat(2, 15%);
  align-items: center;
}

.visit-new-btn {
  height: 40px;
}

.visit-new-container {
  width: 100%;
  justify-content: center;
  display: flex;
  margin-top: 10px;
  padding-left: 25px;
  padding-right: 25px;
}

.loading-wrapper {
  margin-top: 50px;
}

.partial-loader--text {
  font-size: 0.875rem;
  text-align: center;
  margin: auto;
  max-width: 300px;
}

