:export {
  yellowProgressBar: #ebcf3c;
  blueProgressBar: #2C70E3;
  trainingBgColor: #fed673;
  white: #ffffff;
  bodyBgColor: #f6f6f6;
  primaryColor: #16a34a;
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0) scaleY(1) scaleX(1);
    transform: translate(-50%, 0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-36px);
    transform: translateY(-36px);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom-up
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom-up {
  0% {
    -webkit-transform: translate(0%, 1000px);
    transform: translate(0%, 1000px);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(0%, 0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
:root {
  --gap-ratio: 0.5;
  --char-w: 0.95ch;
  --gap: calc(var(--gap-ratio) * var(--char-w));
  --n-char: 10;
  --in-w: calc(var(--n-char) * (var(--char-w) + var(--gap)));
}

.profile-hearder-container {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.profile-title {
  height: 30px;
  font-size: 18px;
  font-weight: 400;
  width: fit-content;
  margin: auto;
}

.profile-user-info {
  text-align: center;
  margin-top: 20px;
}

.profile-name {
  color: #231f20;
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-transform: uppercase;
}

.profile-id {
  font-size: 16px;
  font-weight: 400;
}

.profile-logout-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  right: 5px;
  border: 1px solid #2c70e3;
}

.profile-buttons-container {
  margin-top: 40px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}

.profile-button {
  display: flex;
  border-radius: 5px;
  width: 75px;
  height: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  gap: 5px;
  background-color: #ffffff;
  color: #2c70e3;
}

.deposits-link {
  color: #2c70e3;
}

.profile-card-session {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  align-self: stretch;
  background: #ffffff;
  text-align: center;
  border-radius: 20px 20px 0px 0px;
  padding: 20px 15px;
  margin: 32px 0px 16px;
}

.header-month {
  color: #6b6b6b;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-transform: capitalize;
}

.header-card-text {
  color: #231f20;
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  padding-top: 5px;
}

.weekly-objectives {
  display: flex;
  padding: 10px 0px 20px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.client-card--header {
  color: #2c70e3;
}

.weekly-objectives .card-parent {
  display: flex;
  width: 168px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background: #f6f6f6;
}

.performance-card-parent {
  background: #ffffff;
  box-shadow: 1px 4px 12px 0px rgba(133, 124, 124, 0.06);
  padding: 12px 16px;
  width: 100%;
}

.client-card--header {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.target-progressbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 150px;
  padding-bottom: 5px;
  padding-top: 30px;
}

.progress-numbers {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
}

.progress-value {
  color: #2c70e3;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.progress-max {
  color: #999999;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  line-height: 150%;
  font-weight: 600;
}

.progress-value.blue,
.progress-max.blue {
  color: #2c70e3;
  font-weight: 700;
  font-size: 24px;
}

.ve-card-sale--header-title {
  color: var(--Greys-Dark-grey, #1E1E19);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-transform: uppercase;
}
.ve-card-sale--header-month {
  color: #6B6B6B;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.ve-card-sale--total-payment {
  color: #979797;
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  display: flex;
  padding: 2px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
}
.ve-card-sale--progressbar {
  display: flex;
  padding: 10px 0px;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  color: #231F20;
  text-align: center;
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.ve-card-sale--sales-title {
  color: #16a34a;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.ve-card-sale--not-available {
  margin-bottom: 20px;
}

.text-green {
  color: #16a34a;
}

.ve-card-sale .splide__pagination {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.splide__pagination__item {
  margin: 0 5px;
}

.splide__pagination__page.is-active {
  background: #8AD1A4;
}

.profile--card-deposited-text {
  font-weight: 600;
  font-size: 32px;
  line-height: 150%;
  color: #2c70e3;
}
.profile--card-row {
  margin: 50px 0px;
}
.profile--card-deposits {
  width: 100%;
  padding: 0px 10px;
  cursor: pointer;
}

