@use '../../scss/abstract/variables';

.deposits {
    &--total {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: variables.$very-light-blue;
        border-radius: 10px;
        padding: 20px;
        margin: 0px 25px;
        gap: 20px;

        &-container {
            width: 100%;
        }

        &-icons {
            display: flex;
            align-items: center;
            gap: 10px;
        }

        &-text {
            font-size: 20px;
            font-weight: 600;
        }
    }
}
