@use '../../scss/abstract/variables';

.farmer-id {
    &--parent {
        text-align: center;
    }

    &--header {
        font-size: clamp(1.5rem, 2.5vw, 1.5rem);
        font-weight: 600;
        &-parent {
            margin: 30px 0px;
        }
    }

    &--video {
        width: 100%;
        height: auto;
    }

    &--manual-link-parent {
        padding: 14px 16px;
        background-color: variables.$white;
        border-radius: 50px;
        border: 1px solid rgba(22, 163, 74, 0.5);
        font-size: 12px;
        font-weight: 500;
        margin-top: 20px;
    }

    &--search-text {
        font-weight: 500;
        font-size: 12px;
        color: variables.$dark-grey;
    }

    &--name {
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        text-transform: capitalize;
    }

    &--client-code {
        font-size: 14px;
        line-height: 21px;
    }

    &--modal {
        .modal--body-center {
            height: 79%;
        }
    }

    &--modal-icons {
        margin-top: 156px;
    }

    &--section-icon {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    &--section-icon:last-child {
        margin-top: 52px;
    }

    &--single-icon {
        cursor: pointer;
        border: 1px solid variables.$primary-color;
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-bottom: 25px;
    }

    &--circle {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin: 0 auto;
        margin-bottom: 20px;
        background: variables.$in-progress-modal-bg;
    }

    &--client-data {
        background: rgba(22, 163, 74, 0.15);
        position: absolute;
        left: 0;
        right: 0px;
        top: 0px;
        padding: 20px;
    }
}

.scanner-label {
    margin-top: 20px;
}
