:export {
  yellowProgressBar: #ebcf3c;
  blueProgressBar: #2C70E3;
  trainingBgColor: #fed673;
  white: #ffffff;
  bodyBgColor: #f6f6f6;
  primaryColor: #16a34a;
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0) scaleY(1) scaleX(1);
    transform: translate(-50%, 0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-36px);
    transform: translateY(-36px);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom-up
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom-up {
  0% {
    -webkit-transform: translate(0%, 1000px);
    transform: translate(0%, 1000px);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(0%, 0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
:root {
  --gap-ratio: 0.5;
  --char-w: 0.95ch;
  --gap: calc(var(--gap-ratio) * var(--char-w));
  --n-char: 10;
  --in-w: calc(var(--n-char) * (var(--char-w) + var(--gap)));
}

.ve-ranking--parent {
  margin-top: 40px;
}
@media (max-width: 540px) {
  .ve-ranking--parent {
    margin-top: 16px;
  }
}
.ve-ranking--behind-section {
  margin-top: 24px;
  margin-bottom: 16px;
}
@media (max-width: 540px) {
  .ve-ranking--behind-section {
    margin-top: 16px;
  }
}
.ve-ranking--behind-body .client-contact-card {
  position: relative;
}
.ve-ranking--behind-body .client-contact-card .registered-progressbar--parent {
  padding-left: 10px;
  flex: 0.4;
}
@media (max-width: 540px) {
  .ve-ranking--behind-body .client-contact-card .registered-progressbar--parent {
    padding-left: 0px;
  }
}
.ve-ranking--behind-body .client-contact-card .card-client-name-abbr {
  min-width: 200px;
}
@media (max-width: 540px) {
  .ve-ranking--behind-body .client-contact-card {
    margin-bottom: 0px;
    border-bottom: 1px solid #e4e4e7;
    border-radius: 0px;
  }
  .ve-ranking--behind-body .client-contact-card:last-of-type {
    border-bottom: none;
  }
}
@media (max-width: 540px) {
  .ve-ranking--behind-body .client-contact-card .rank-parent {
    position: absolute;
    right: 0;
    top: 0;
    left: clamp(120px, 82%, 400px);
  }
}
@media (max-width: 375px) {
  .ve-ranking--behind-body .client-contact-card .rank-parent {
    position: absolute;
    right: 0;
    top: 0;
    left: clamp(120px, 82%, 400px);
  }
}
@media (max-width: 320px) {
  .ve-ranking--behind-body .client-contact-card .rank-parent {
    position: absolute;
    right: 0;
    top: 0;
    left: clamp(120px, 82%, 400px);
  }
}
.ve-ranking--behind-body .card-client-parent {
  gap: 20px;
}
@media (max-width: 540px) {
  .ve-ranking--behind-body .card-client-parent {
    gap: 0px;
  }
}
@media (max-width: 540px) {
  .ve-ranking--behind-body {
    border: 1px solid #e4e4e7;
    border-radius: 8px;
    overflow: hidden;
  }
  .ve-ranking--behind-body .card-client-parent {
    flex-direction: column;
    align-items: flex-start;
  }
}
.ve-ranking--ranking-alignment .card-client-parent {
  gap: 20px;
  display: grid;
  grid-template-columns: 4fr 1fr;
}
@media (max-width: 540px) {
  .ve-ranking--ranking-alignment .card-client-parent {
    gap: 0px;
    display: grid;
    grid-template-columns: 1fr;
  }
}
@media (max-width: 540px) {
  .ve-ranking--progress-bar {
    border-left: none;
    font-size: 12px;
    width: calc(100% - 100px);
    margin-top: 4px;
    margin-bottom: 6px;
    margin-left: 58px;
  }
  .ve-ranking--progress-bar:last-of-type {
    margin-bottom: 0px;
  }
  .ve-ranking--progress-bar .stats-icon {
    width: 12px;
  }
}
.ve-ranking--header-section {
  margin-bottom: 16px;
  font-size: 16px;
}
@media (max-width: 540px) {
  .ve-ranking--header-section {
    margin-bottom: 8px;
  }
}

.ranking-target-percentage {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}
.ranking-target-percentage .ranking-enrolled {
  font-size: 12px;
  display: flex;
  gap: 6px;
  color: #636363;
  border-left: 1px solid #e1e1e1;
  padding-left: 10px;
}
@media (max-width: 540px) {
  .ranking-target-percentage {
    margin-left: 58px;
  }
  .ranking-target-percentage .ranking-enrolled {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    gap: 6px;
    margin-bottom: 4px;
  }
}

.ranking-enrolled {
  font-size: 12px;
  display: flex;
  gap: 6px;
  color: #636363;
  border-left: 1px solid #e1e1e1;
  padding-left: 10px;
}
@media (max-width: 540px) {
  .ranking-enrolled {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    gap: 6px;
    margin-bottom: 4px;
    margin-left: 58px;
    padding-left: 0px;
    border-left: 0px;
  }
}

.ranking-village .card-client-data .card-client-id--payment {
  color: #1e1e19;
}

.objective-revenue--parent {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
}
.objective-revenue--parent .registered-progressbar--parent.ve-ranking--progress-bar {
  flex: 1;
}
.objective-revenue--parent .registered-progressbar--parent.ve-ranking--progress-bar:last-of-type {
  margin-left: 5px;
  padding-left: 5px;
  margin-top: 0px;
  position: relative;
}
@media (max-width: 540px) {
  .objective-revenue--parent .registered-progressbar--parent.ve-ranking--progress-bar:last-of-type::before {
    position: absolute;
    content: "";
    top: 50%;
    bottom: 0px;
    width: 1px;
    background-color: #e1e1e1;
    height: 80%;
    left: 0px;
    transform: translateY(-50%);
  }
}

