:export {
  yellowProgressBar: #ebcf3c;
  blueProgressBar: #2C70E3;
  trainingBgColor: #fed673;
  white: #ffffff;
  bodyBgColor: #f6f6f6;
  primaryColor: #16a34a;
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0) scaleY(1) scaleX(1);
    transform: translate(-50%, 0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-36px);
    transform: translateY(-36px);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom-up
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom-up {
  0% {
    -webkit-transform: translate(0%, 1000px);
    transform: translate(0%, 1000px);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(0%, 0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
:root {
  --gap-ratio: 0.5;
  --char-w: 0.95ch;
  --gap: calc(var(--gap-ratio) * var(--char-w));
  --n-char: 10;
  --in-w: calc(var(--n-char) * (var(--char-w) + var(--gap)));
}

.notification--parent {
  margin-top: 19px;
}
.notification--header {
  margin-bottom: 20px;
}
.notification--spinner-parent {
  margin-top: 40px;
}
.notification--body::before {
  content: "";
  position: absolute;
  top: 155px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #ffffff;
}
.notification--container {
  background: #ffffff;
  margin-top: 80px;
}
.notification--no-notification {
  margin-top: 50px;
  text-align: center;
  font-size: 20px;
  color: #e85b5b;
}

.payment-notification {
  border-radius: 24px;
  padding: 12px 24px;
  background-color: #ffffff;
  margin-bottom: 15px;
}
.payment-notification:last-child {
  margin-bottom: 0px;
}
.payment-notification--card-number {
  font-size: 1.188rem;
  color: #1e1e19;
  font-weight: 600;
}
.payment-notification--client-code {
  font-size: 0.875rem;
  font-weight: 400;
  color: #636363;
}
.payment-notification--client-code--green {
  color: #16a34a;
}
.payment-notification--icon-parent {
  width: 47px;
  height: 47px;
  border-radius: 100%;
  border: 1px solid #16a34a;
  display: flex;
  justify-content: center;
  align-items: center;
}
.payment-notification--icon-parent.pending {
  border: 1px solid #979797;
}
.payment-notification--icon-parent.offline {
  border: 1px solid #979797;
}
.payment-notification--icon-parent.failed {
  border: 1px solid #e85b5b;
}
.payment-notification--row {
  margin-bottom: 27px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.payment-notification--row:last-child {
  margin-bottom: 0px;
  align-items: flex-end;
}
.payment-notification--date-time {
  font-size: 0.75rem;
  font-weight: 400;
  color: #636363;
}
.payment-notification--amount {
  color: #2f2f20;
  font-size: 1.063rem;
  font-weight: 600;
}

.client-code--green {
  color: #16a34a;
  text-decoration: underline;
}

.notification-filter {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.notification-filter--parent {
  display: flex;
  justify-content: space-between;
  gap: 17px;
  margin-bottom: 32px;
}
.notification-filter--icon-parent {
  width: 39px;
  height: 39px;
  background-color: #ffffff;
  border-radius: 100%;
  border: 1px solid #979797;
  margin-bottom: 18px;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notification-filter--icon-parent.active {
  border: 1px solid #16a34a;
}
.notification-filter--text {
  color: #000000;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 500;
}
.notification-filter--text-parent {
  padding: 5px 10px;
  border: 1px solid #e4e4e7;
  border-radius: 9px;
  background-color: #e4e4e7;
}
.notification-filter--text-parent.active {
  border: 1px solid #16a34a;
  background-color: #ffffff;
}

.notification-search {
  border: 1px solid #979797;
  border-radius: 24px;
  padding: 12px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  margin-bottom: 31px;
  margin-top: 13px;
}
.notification-search--input {
  flex: 1;
  padding: 0px 10px;
  font-family: "Work Sans";
}
.notification-search--icon {
  display: flex;
  margin-right: 12px;
}

.notification-modal {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.2117647059);
  z-index: 9999999999999;
}
.notification-modal--bg {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}
.notification-modal--close {
  position: absolute;
  top: 21px;
  right: 29px;
}
.notification-modal--main {
  background: #fff;
  border-radius: 20px;
  height: 70%;
  margin: auto;
  width: 80%;
  z-index: 99999999999999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 52px 30px 46px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.notification-modal--msg {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  text-align: center;
}
.notification-modal--msg-parent {
  width: 80%;
  margin: auto;
}
.notification-modal--icon-parent {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #979797;
}
.notification-modal--icon-parent.failed {
  border: 1px solid #e85b5b;
}
.notification-modal--icon-parent.success {
  border: 1px solid #16a34a;
}
.notification-modal--icon-parent svg {
  width: 14px;
}
.notification-modal--header {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}
.notification-modal--header-parent {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
}
.notification-modal--details-header {
  font-size: 14px;
  color: #000000;
  font-weight: 400;
}
.notification-modal--details-body {
  margin-top: 13px;
}
.notification-modal--details-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  align-items: center;
}
.notification-modal--details-row:last-child {
  margin-bottom: 0px;
}
.notification-modal--details-content {
  font-size: 12px;
  color: #000000;
  font-weight: 600;
}
.notification-modal--details-text {
  font-weight: 400;
}
.notification-modal--status-tag {
  padding: 3px 5px;
  border-radius: 5px;
  background-color: #d9d9d9;
}
.notification-modal--status-tag.failed {
  background-color: #fcd2cc;
}
.notification-modal--status-tag.success {
  background-color: #bee9d6;
}
.notification-modal--status-tag-text {
  font-size: 12px;
  color: #000000;
  font-weight: 500;
}

.no-payment-notification {
  padding: 54px 27px;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  text-align: center;
  max-width: 267px;
  margin: auto;
  margin-top: 70px;
  font-size: 14px;
}

.single-notification--container {
  margin-top: 20px;
}
.single-notification--container:last-child {
  margin-bottom: 85px;
}
.single-notification--container:last-child .single-notification--content {
  border-bottom: 0px;
}
.single-notification--content {
  display: flex;
  position: relative;
  padding: 31px 20px;
  gap: 20px;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  margin-top: 10px;
}
.single-notification--content-unread {
  background: rgba(44, 112, 227, 0.1);
}
.single-notification--icon {
  flex: 0;
  display: flex;
}
.single-notification--text {
  flex: 1;
}
.single-notification--text-content {
  color: #636363;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: -webkit-box;
  max-width: fit-content;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.single-notification--dot {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ff0000;
}
.single-notification--date {
  font-size: 12px;
  font-weight: 500;
  color: #636363;
  line-height: 18px;
  text-transform: capitalize;
}

.status-notification {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 70px;
}

.status-icon {
  margin-top: 40px;
}

.top-close-bg {
  background: #eaf1fc;
  height: 64px;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
}

.modal-content-parent {
  min-height: 200px;
  margin-top: 54px;
}

.modal-content {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: #636363;
}

.modal--body-bottom {
  padding-top: 20px;
}

