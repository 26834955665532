:export {
  yellowProgressBar: #ebcf3c;
  blueProgressBar: #2C70E3;
  trainingBgColor: #fed673;
  white: #ffffff;
  bodyBgColor: #f6f6f6;
  primaryColor: #16a34a;
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0) scaleY(1) scaleX(1);
    transform: translate(-50%, 0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-36px);
    transform: translateY(-36px);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom-up
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom-up {
  0% {
    -webkit-transform: translate(0%, 1000px);
    transform: translate(0%, 1000px);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(0%, 0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
:root {
  --gap-ratio: 0.5;
  --char-w: 0.95ch;
  --gap: calc(var(--gap-ratio) * var(--char-w));
  --n-char: 10;
  --in-w: calc(var(--n-char) * (var(--char-w) + var(--gap)));
}

.card-parent {
  width: 100%;
  padding: 12px 16px;
  border-radius: 20px;
  box-shadow: 1px 4px 12px 0 rgba(133, 124, 124, 0.06);
  background-color: #ffffff;
}

.no-shadow {
  box-shadow: none;
}

.card-client-parent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}
.card-client-parent .card-client-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
@media (max-width: 425px) {
  .card-client-parent .card-client-data {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    gap: 1px;
  }
}

.card-client-text-color {
  color: #636363;
}

.card-client--no-spread {
  justify-content: initial;
}

.card-client-abbr--small {
  width: 56px;
  height: 56px;
  font-size: 16px;
}

.card-client-abbr--large {
  width: 64px;
  height: 64px;
}

.card-client-abbr-payment--small {
  width: 48px;
  height: 48px;
  font-size: 12px;
}
@media (max-width: 540px) {
  .card-client-abbr-payment--small {
    width: 48px;
    height: 48px;
  }
}

.card-client-data {
  margin-left: 10px;
  font-family: "Work Sans", sans-serif;
  font-stretch: normal;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}
.card-client-data .card-client-name {
  font-size: clamp(0.875rem, 2.5vw, 1rem);
  font-weight: 600;
  color: #2f2f20;
  text-transform: capitalize;
}
.card-client-data .card-client-id {
  font-size: 14px;
  color: #6b6b6b;
}
.card-client-data .card-client-id--client {
  font-size: clamp(0.875rem, 2.5vw, 1rem);
  color: #636363;
}
.card-client-data .card-client-id--payment {
  font-size: clamp(0.75rem, 2.5vw, 0.875rem);
  color: #636363;
  margin-right: 4px;
  text-transform: capitalize;
}
.card-client-data .card-client-phone {
  font-size: 19px;
  font-weight: 600;
  text-align: left;
  color: #636363;
  margin-right: 4px;
}
.card-client-data .card-client-phone--icon {
  display: flex;
  align-items: center;
}
.card-client-data .card-client-phone--icon svg path {
  stroke: #636363;
  opacity: 0.7;
}
@media (max-width: 540px) {
  .card-client-data .card-client-phone {
    font-size: 16px;
  }
}
.card-client-data--name {
  font-size: 19px;
  font-weight: 600;
  color: #1e1e19;
}
@media (max-width: 540px) {
  .card-client-data--name {
    font-size: 16px;
    text-transform: capitalize;
  }
}

.card-client-footer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media (max-width: 540px) {
  .card-client-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.card-pipe {
  border-right: 1px solid #979797;
  display: inline-block;
  margin: 0px 4px;
  height: 14px;
}

.card-phone-icon {
  display: flex;
  align-items: center;
  min-width: 115px;
}
@media (max-width: 540px) {
  .card-phone-icon {
    min-width: initial;
  }
}
.card-phone-icon .card-phonenumber {
  margin-right: 4.5px;
  font-weight: 600;
  color: #636363;
  font-size: clamp(0.75rem, 2.5vw, 0.875rem);
}
@media (max-width: 540px) {
  .card-phone-icon .card-phonenumber {
    font-weight: 500;
  }
}

.display-mobile .card-client-payment {
  display: none;
}
@media (max-width: 540px) {
  .display-mobile .card-client-payment {
    display: flex;
    column-gap: 4px;
    justify-content: flex-start;
  }
}

.card-client-action {
  font-size: 12px;
  font-weight: 500;
  color: #636363;
}

.client-card-payment--label {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #6b6b6b;
}

.client-card-payment--label-completed {
  color: #16a34a;
  font-weight: 600;
}

.card-client-status, .unknown, .potential_client,
.payment_no_package,
.duplicate, .package_no_payment, .enrolled,
.finished {
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.client-card--red {
  color: #e85b5b;
}

.client-card--green {
  color: #9a8603;
}

.client-card--good {
  color: #16a34a;
}

.commission-duration-parent {
  font-size: 19px;
  font-weight: 600;
  color: #2f2f20;
}
.commission-duration-parent .commission-duration {
  font-weight: normal;
  color: #545454;
}
@media (max-width: 420px) {
  .commission-duration-parent {
    font-size: 16px;
  }
}

.commission-amount {
  font-size: 28px;
  font-weight: bold;
  color: #096127;
}
@media (max-width: 420px) {
  .commission-amount {
    font-size: 23px;
  }
}

.card-client-name-abbr {
  display: flex;
  align-items: center;
  min-width: 245px;
}
@media (max-width: 420px) {
  .card-client-name-abbr {
    min-width: initial;
  }
}
@media (max-width: 943px) {
  .card-client-name-abbr {
    min-width: 0px;
  }
}

.card-client-payment {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  color: #636363;
  flex-wrap: wrap;
  font-size: clamp(0.75rem, 2.5vw, 0.875rem);
  column-gap: 10px;
}
@media (max-width: 540px) {
  .card-client-payment {
    display: none;
  }
}

.card-payment-amount-date .payment-amount-date {
  font-weight: 600;
}
.card-payment-amount-date .completed-payment {
  color: #16a34a;
}

.card-payment-left {
  font-size: clamp(0.71rem, 2.1vw, 0.871rem);
}
.card-payment-left .amount-left {
  color: #16a34a;
}

.card-pipe-left {
  position: relative;
  padding-left: 4px;
}
.card-pipe-left::before {
  position: absolute;
  content: "";
  height: 60%;
  border-left: 1px solid #979797;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
}

.card-sales-parent {
  max-width: 123px;
  margin: auto;
  color: #1e1e19;
  position: relative;
  cursor: pointer;
}
@media (max-width: 540px) {
  .card-sales-parent {
    max-width: 96px;
    margin: auto;
  }
}
.card-sales-parent .card-sales--bold {
  font-weight: bold;
}
.card-sales-parent .card-sales {
  border-radius: 13px;
  text-align: center;
  padding-top: 35px;
  font-size: 20.7px;
  color: #000000;
  position: relative;
}
@media (max-width: 540px) {
  .card-sales-parent .card-sales {
    font-size: 16px;
    padding-top: 27px;
  }
}
.card-sales-parent .card-sales--price {
  transform: translateY(0px);
  transition: all 0.5s;
}
.card-sales-parent .card-sales--logo-parent {
  height: 72px;
  overflow: hidden;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
  position: relative;
}
@media (max-width: 540px) {
  .card-sales-parent .card-sales--logo-parent {
    height: 56px;
  }
}
.card-sales-parent .card-sales .selected-number {
  position: absolute;
  z-index: 10;
  width: 108px;
  background: black;
  color: #ffffff;
  border-radius: 60px;
  bottom: 15px;
  padding: 6px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 21px;
  transition: all 0.5s;
}
.card-sales-parent .card-sales .selected-number--content {
  font-weight: bold;
}
@media (max-width: 540px) {
  .card-sales-parent .card-sales .selected-number {
    width: 83px;
    font-size: 17px;
  }
}
.card-sales-parent .card-sales--logo {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 10px;
}
.card-sales-parent .card-sales--logo > * {
  z-index: 9;
}
.card-sales-parent .card-sales--logo:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #555555;
  width: 100%;
  height: 100%;
  border-top-right-radius: 13px;
  transform: skewY(14deg);
  transform-origin: -2% 100%;
  z-index: 1;
}
@media (max-width: 540px) {
  .card-sales-parent .card-sales--logo:after {
    transform: skewY(11deg);
  }
}
.card-sales-parent .card-sales:after {
  min-height: 138px;
  content: "";
  position: absolute;
  background: #eceded;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: -1;
  bottom: 0px;
  border-radius: 13px;
  transition: all 0.5s;
}
@media (max-width: 540px) {
  .card-sales-parent .card-sales:after {
    min-height: 107px;
  }
}
.card-sales-parent .card-sales-out {
  background: rgba(2, 2, 2, 0.2);
  color: #abaeb1;
}
.card-sales-parent .card-sales-selected:after {
  top: -18px;
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: 0 4px 10px 0 #cbcad4;
}
.card-sales-parent .card-sales-selected .card-sales--price {
  transform: translateY(-10px);
  transition: all 0.5s;
}
.card-sales-parent .card-sales-label {
  text-align: center;
  margin-top: 8px;
  line-height: 1.5;
}
.card-sales-parent .card-sales-label--stock {
  font-size: 14px;
}
@media (max-width: 540px) {
  .card-sales-parent .card-sales-label--stock {
    font-size: 10px;
  }
}
.card-sales-parent .card-sales-label--quantity {
  font-size: 19px;
}
@media (max-width: 540px) {
  .card-sales-parent .card-sales-label--quantity {
    font-size: 14px;
  }
}
.card-sales-parent .card-sales-no-card {
  background: #ff5f04;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -21px;
  right: 0px;
}
@media (max-width: 540px) {
  .card-sales-parent .card-sales-no-card {
    width: 32px;
    height: 32px;
  }
}
.card-sales-parent .selected-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #ffffff;
  position: absolute;
  top: -34px;
  right: -16px;
  transition: all 0.5s;
}
@media (max-width: 540px) {
  .card-sales-parent .selected-icon {
    width: 24px;
    height: 24px;
    top: -29px;
    right: -10px;
  }
}

.card-village-parent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 6px 0px;
}
@media (max-width: 540px) {
  .card-village-parent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 0px;
  }
}

.card-village--name {
  color: #16a34a;
  border-bottom: 1px solid #e1e1e1;
  width: 100%;
  text-align: center;
  padding-bottom: 11px;
}
@media (max-width: 540px) {
  .card-village--name {
    flex: 0.7;
    border-bottom: none;
    width: initial;
    text-align: left;
    padding-bottom: 0px;
  }
}
.card-village--name-only {
  border-bottom: 0px;
  padding-bottom: 0px;
}
@media (max-width: 540px) {
  .card-village--name-only {
    flex: 0;
  }
}
.card-village--village {
  font-weight: 500;
  font-size: 19px;
}
@media (max-width: 540px) {
  .card-village--village {
    font-size: 16px;
  }
}
.card-village--count {
  display: flex;
  justify-content: space-between;
  flex: 1;
  position: relative;
  width: 100%;
  max-width: initial;
  margin-top: 12px;
}
@media (max-width: 540px) {
  .card-village--count {
    max-width: 155px;
    margin-top: 0px;
    padding-left: 12px;
  }
}
.card-village--count-section {
  text-align: center;
  flex: 1;
  position: relative;
}
.card-village--count-section:first-of-type:after {
  content: "";
  position: absolute;
  height: 80%;
  width: 1px;
  top: 50%;
  transform: translateY(-50%);
  bottom: 0px;
  right: 0px;
  background: #e1e1e1;
}
@media (max-width: 540px) {
  .card-village--count-section:first-of-type:after {
    content: none;
  }
}
.card-village--count-header {
  font-size: 14px;
  font-weight: 500;
  color: #6b6b6b;
}
@media (max-width: 540px) {
  .card-village--count-header {
    font-size: 12px;
  }
}
.card-village--count-number {
  font-size: 19px;
  font-weight: 500;
  color: #1e1e19;
}
@media (max-width: 540px) {
  .card-village--count-number {
    font-size: 14px;
  }
}
.card-village--count:after {
  content: none;
  position: absolute;
  height: 80%;
  width: 1px;
  top: 50%;
  transform: translateY(-50%);
  bottom: 0px;
  left: 0px;
  background: #6b6b6b;
}
@media (max-width: 540px) {
  .card-village--count:after {
    content: "";
  }
}

.card-client-detail--top {
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  margin-top: 16px;
}
@media (max-width: 540px) {
  .card-client-detail--top {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 0px;
  }
}
.card-client-detail--top .name-abr-id {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: 10px;
}
.card-client-detail--bottom {
  padding: 0px 8px;
  padding-top: 24px;
  margin-top: 23px;
  place-items: center;
}
@media (max-width: 540px) {
  .card-client-detail--bottom {
    padding: 0px;
    padding-top: 16px;
    margin-top: 15px;
  }
}
.card-client-detail--bottom .client-other-single {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  font-size: 16px;
}
.card-client-detail--bottom .client-other-single:last-child {
  margin-bottom: 7px;
}
.card-client-detail--bottom .client-other-single .client-other-key {
  color: #1e1e19;
}
.card-client-detail--bottom .client-other-single .client-other-value {
  color: #000000;
  font-weight: 500;
}
.card-client-detail--modify {
  position: absolute;
  right: 16px;
  top: -10px;
}
@media (max-width: 540px) {
  .card-client-detail--modify {
    position: relative;
    left: 0px;
    display: block;
    text-align: right;
    top: 0px;
  }
}
.card-client-detail--icons {
  padding-block: 10px;
}
.card-client-detail--name-icon {
  display: flex;
  align-items: center;
}
.card-client-detail--name {
  font-size: 19px;
  font-weight: 600;
  color: #2f2f20;
  margin-bottom: 2px;
  margin-right: 6px;
  text-transform: capitalize;
}
@media (max-width: 540px) {
  .card-client-detail--name {
    font-size: 16px;
    margin: 0px auto;
  }
}
.card-client-detail--id {
  font-size: 16px;
  color: #6b6b6b;
  margin-bottom: 16px;
}
@media (max-width: 540px) {
  .card-client-detail--id {
    font-size: 14px;
  }
}
.card-client-detail--contact-interactions {
  display: flex;
  width: 50%;
  margin: 25px auto;
}
.card-client-detail--button, .card-client-detail--client-phone-duplicate, .card-client-detail--client-phone, .card-client-detail--button-interactions, .card-client-detail--button--hide {
  flex: 1;
  height: 32px;
  font-size: clamp(10px, 3vw, 14px);
  font-weight: 500;
  border-radius: 4px;
  width: 50%;
  justify-content: space-evenly;
}
@media (max-width: 540px) {
  .card-client-detail--button, .card-client-detail--client-phone-duplicate, .card-client-detail--client-phone, .card-client-detail--button-interactions, .card-client-detail--button--hide {
    font-size: 14px;
  }
}
.card-client-detail--button--hide {
  flex: 0.5;
  margin: auto;
}
.card-client-detail--button-interactions {
  border-radius: 4px;
  height: 42px;
  width: 95%;
  margin: auto;
  margin-top: 15px;
  justify-content: center;
  padding: 30px;
}
.card-client-detail--client-phone {
  border-radius: 10px;
  padding: 20px;
}
.card-client-detail--client-phone-duplicate {
  border-radius: 10px;
  padding: 20px;
  background-color: #d9d9d9;
}
.card-client-detail--empty-icon, .client-detail-profile--empty-avatar, .client-full-details--empty-age-group {
  padding-block: 10px;
  width: 56px;
  height: 56px;
  margin: 0 auto;
  border-radius: 60px;
  background: #c4c4c4;
}

.client-phone {
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
  margin: 0 auto;
}

.client-full-details {
  display: grid;
  gap: 5px;
}
.client-full-details--age-group-role-container {
  display: flex;
  justify-content: space-between;
  width: 30%;
  margin: 0 auto;
}
.client-full-details--age-group-role {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 24px;
  border: 1px solid #000000;
  background: #ffffff;
}
.client-full-details--empty-age-group {
  width: 40px;
  height: 40px;
  border-radius: 24px;
}
.client-full-details--age-group-text {
  font-size: 12px;
}

.client-detail-profile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  margin: 0 auto;
  gap: 15px;
}
.client-detail-profile--empty-avatar {
  margin: 0;
}

.interactions-container, .interactions-container--hide, .interactions-container--show {
  width: 90%;
  margin: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  overflow: hidden;
  transition: max-height 0.4s ease-out;
}

.interactions-container--show {
  max-height: 300px;
}

.interactions-container--hide {
  max-height: 0;
}

.card-client-detail-abr {
  width: 64px;
  height: 64px;
  font-size: 19px;
  margin-bottom: 16px;
}
@media (max-width: 540px) {
  .card-client-detail-abr {
    width: 56px;
    height: 56px;
    font-size: 16px;
    margin-right: 10px;
    margin-bottom: 12px;
  }
}

.view-more {
  border-top: 1px solid #e1e1e1;
  padding: 13px 0px 1px;
  text-align: center;
  margin-top: 15px;
  display: none;
  font-size: 14px;
}
@media (max-width: 540px) {
  .view-more {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.view-more .icon-up {
  transition: all 0.5s ease-out;
  transform: rotate(0deg);
  display: flex;
}
.view-more .icon-down {
  transition: all 0.5s ease-out;
  transform: rotate(180deg);
  display: flex;
}
.view-more .close-display {
  color: #16a34a;
}

@media (max-width: 540px) {
  .hideMore {
    max-height: 0px;
    overflow: hidden;
    transition: all 0.35s ease-out;
    border-top: 0px;
    padding-top: 0px;
    margin-top: 0px;
  }
}

.showMore {
  max-height: 400px;
  transition: all 0.4s ease-in;
  overflow: hidden;
}

.registered-progressbar--parent {
  flex: 0.3;
  border-left: 1px solid #e1e1e1;
}
.registered-progressbar--parent .registered-progressbar--stats {
  font-size: 0.75rem;
}
.registered-progressbar--label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  color: #1e1e19;
}
.registered-progressbar--target-result {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #2f2f20;
}
.registered-progressbar--target-result .result {
  font-size: clamp(1rem, 2.5vw, 1.125rem);
  font-weight: bold;
}
.registered-progressbar--target-result .target--parent {
  display: flex;
  font-size: clamp(0.875rem, 2.5vw, 1em);
  font-weight: bold;
}
.registered-progressbar--target-result .target--parent .target--content {
  margin-left: 5px;
  font-weight: bold;
}
.registered-progressbar--target-result .target--parent .target--of {
  color: #abaeb1;
}

.show-mobile {
  display: none;
}
@media (max-width: 540px) {
  .show-mobile {
    display: block;
  }
}

.hide-mobile {
  display: block;
}
@media (max-width: 540px) {
  .hide-mobile {
    display: none;
  }
}

.card-summary-item {
  padding: 0px 8px;
  padding-top: 18px;
  margin-top: 20px;
  border-top: 1px solid #e1e1e1;
}

.card-summary-single {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  font-size: 16px;
}

.card-summary-other-key {
  color: #1e1e19;
  margin-right: 10px;
}

.card-summary-other-value {
  color: #000000;
  font-weight: 500;
  max-width: 150px;
  text-align: right;
}

.card-summury-content .card-summary-item:first-child {
  margin-top: 10px;
}

.card-summary-product-price {
  margin-left: 24px;
  font-weight: bold;
}

.card-summary-header {
  display: flex;
}
.card-summary-header .card-summary-top-link {
  margin-left: auto;
  cursor: pointer;
}

.payment-color--red {
  color: #e85b5b;
}
.payment-color--green {
  color: #16a34a;
}
.payment-color--yellow {
  color: #ebcf3c;
}

.card-delivery--delivered {
  color: #16a34a;
}
.card-delivery--pre-certified {
  color: #ebcf3c;
}

.card-client-status, .unknown, .potential_client,
.payment_no_package,
.duplicate, .package_no_payment, .enrolled,
.finished {
  text-align: center;
  margin: 5px 0;
}

.card--product-parent {
  padding-right: 10px;
  padding-top: 10px;
}

.card-product {
  width: 140px;
  height: 140px;
  border-radius: 13px;
  border-width: 1px;
  border-style: solid;
  border-color: #b5b5b5;
  background-color: #d0eddb;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}
.card-product--selected {
  border-color: #186d2a;
  border-width: 7px;
}
.card-product--selected .card-product-picture {
  top: 0;
  left: 0;
  width: 126px;
  height: 126px;
  border-radius: 7px;
}
@media (max-width: 370px) {
  .card-product--selected .card-product-picture {
    width: 116px;
    height: 116px;
  }
}
@media (max-width: 345px) {
  .card-product--selected .card-product-picture {
    width: 106px;
    height: 106px;
  }
}
.card-product--selected .card-product-name {
  position: absolute;
  bottom: 39;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  width: 126px;
}
@media (max-width: 370px) {
  .card-product--selected .card-product-name {
    width: 116px;
  }
}
@media (max-width: 345px) {
  .card-product--selected .card-product-name {
    width: 106px;
  }
}
@media (max-width: 370px) {
  .card-product {
    width: 130px;
    height: 130px;
  }
}
@media (max-width: 345px) {
  .card-product {
    width: 120px;
    height: 120px;
  }
}

.card-product-name {
  background-color: #ffffff;
  font-family: "Work Sans", sans-serif;
  font-stretch: normal;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 600;
  width: 138px;
  height: 39px;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
  border-top: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 39;
}
@media (max-width: 370px) {
  .card-product-name {
    font-size: 0.7rem;
    width: 128px;
  }
}
@media (max-width: 345px) {
  .card-product-name {
    font-size: 0.65rem;
    width: 118px;
  }
}

.card-product-picture {
  width: 138px;
  height: 138px;
  border-radius: 13px;
}
@media (max-width: 370px) {
  .card-product-picture {
    width: 128px;
    height: 128px;
  }
}
@media (max-width: 345px) {
  .card-product-picture {
    width: 118px;
    height: 118px;
  }
}

.card-product-units {
  width: 30px;
  height: 30px;
  display: flex;
  background-color: #186d2a;
  position: absolute;
  top: -15px;
  right: -18px;
  border-radius: 30px;
  color: #ffffff;
  font-weight: 600;
  font-family: "Work Sans", sans-serif;
  align-items: center;
  justify-content: center;
}
@media (max-width: 370px) {
  .card-product-units {
    width: 27px;
    height: 27px;
  }
}
@media (max-width: 345px) {
  .card-product-units {
    width: 25px;
    height: 25px;
  }
}

.card--product-parent {
  background-color: transparent;
  box-shadow: none;
}

.product-selection-custom-pagination .is-active {
  background: rgba(22, 163, 74, 0.5) !important;
}

.enrolled,
.finished {
  color: #16a34a;
}

.package_no_payment {
  color: var(--client-subscribed-color);
}

.potential_client,
.payment_no_package,
.duplicate {
  color: #adacac;
}

.unknown {
  margin-top: 16px;
}

.interaction-enroll-btn, .interaction-duplicate-flag-btn, .interaction-cancel-package-btn,
.interaction-not-interested-btn,
.interaction-request-reimbursement-btn, .interaction-request-follow-up-btn,
.interaction-mobile-money-payment-btn,
.interaction-card-payment-btn {
  border-radius: 15px;
  background-color: #bee9d6;
  height: auto;
  min-height: 31px;
  width: 92%;
  margin: auto;
  color: black;
  font-weight: 400;
  border-color: transparent;
  margin-top: 15px;
  margin-bottom: 15px;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  gap: 10px;
  text-transform: capitalize;
}

.interaction-request-follow-up-btn {
  background-color: #ffe299;
}

.interaction-cancel-package-btn,
.interaction-not-interested-btn,
.interaction-request-reimbursement-btn {
  background-color: #fcd2cc;
}

.interaction-duplicate-flag-btn {
  background-color: #ff1515;
  color: #ffffff;
}

.interaction-btn-small {
  width: 92%;
}

.no-lateral-padding {
  padding-left: 0;
  padding-right: 0;
}

.downloadable-card {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 0;
  font-family: "Work Sans", sans-serif;
}
.downloadable-card--header {
  padding: 15px 15px 35px;
  border-radius: 10px 10px 0 0;
  display: flex;
}
.downloadable-card--status {
  font-size: 10px;
  font-weight: 400;
  background-color: #d9d9d9;
  padding: 3px 5px;
  border-radius: 10px;
  display: flex;
  flex-grow: 0;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.downloadable-card--status--icon {
  height: 15px;
}
.downloadable-card--overview {
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.downloadable-card--overview--icon {
  padding: 5px;
}
.downloadable-card--overview--end-icon {
  margin-left: auto;
}
.downloadable-card--overview--title {
  font-size: 16px;
  font-weight: 600;
  white-space: wrap;
}
.downloadable-card--overview--info {
  padding-left: 5px;
}
.downloadable-card--overview--subtitle {
  font-size: 12px;
  font-weight: 400;
  white-space: wrap;
  color: #6b6b6b;
}

