@use '../../scss/abstract/variables';

.topbar {
    &--title {
        display: flex;
        gap: 10px;
    }
}

.deposits {
    &--parent {
        margin-bottom: 60px;

        &::before {
            content: '';
            position: absolute;
            top: 155px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            background: variables.$white;
        }
    }

    &--container {
        margin-top: 20px;
        background: variables.$white;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
    }

    &--header {
        text-align: center;
        font-weight: 600;
        font-size: 32px;
        margin-top: 20px;
        padding-top: 20px;
    }
    &--remain {
        &-container {
            margin: 50px 0px;
            padding: 0px 10px;
        }
    }
}

.single-deposit {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 15px;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;

    &:last-child {
        border-bottom: 0px;
        padding-bottom: 55px;
    }

    &--date {
        margin-bottom: 16px;
        &-text {
            text-transform: capitalize;
            color: variables.$card-payment-color;
            font-weight: 500;
            font-size: 14px;
        }
    }

    &--content {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 0px 15px;
    }
    &--icon {
        display: flex;
        flex: 0.1;

        &-container {
            display: flex;
        }
    }
    &--payment-icon {
        display: flex;
        flex: 0.1;
    }
    &--amount {
        flex: 0.8;

        &-text {
            &-positive {
                color: variables.$main-blue;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
            }
            &-negative {
                color: variables.$red;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
            }
        }
        &-reference {
            color: variables.$card-payment-color;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
        }
    }
}
