.textfield--parent {
  display: flex;
  position: relative;
}

.textfield-input {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border: solid 1px #cbcad4;
  background-color: #fff;
  box-sizing: border-box;
  padding: 8px 16px;
  font-family: "Work Sans", sans-serif;
}
@media (max-width: 420px) {
  .textfield-input {
    height: 40px;
  }
}

.textfield-input--icon {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  margin-right: 16px;
}

