@use '../../scss/abstract/variables';
@import '../../scss/abstract/mixins';

.enrollment-container {
    padding-top: 15px;
    width: 100%;
    overflow: hidden;
}

.enrollment-form {
    $enrolForm: &;
    display: flex;
    justify-content: space-between;
    width: 300%;
    &--modify-package {
        @extend #{$enrolForm};
        width: 200%;
    }
}

.enrollment-step {
    width: 100%;
    flex-shrink: 1;
    margin-right: 1%;
    height: fit-content;
}

.enrollment-step-container {
    margin-top: 20px;
}

.enrollment-button {
    width: 100%;
    margin-inline: auto;
    margin-block: 15px;
}

.enrollment-button-package-selection {
    width: 90%;
    margin: auto;
    margin-left: 5%;
    margin-top: 15px;
    @media (max-width: 420px) {
        width: 95%;
    }
}

.product-selection-container {
    margin-left: 5%;

    @media (max-width: 380px) {
        margin-left: -3%;
    }
}

.question-form-vertical {
    margin-top: 5px;
    margin-bottom: 10px;
}

.question-form-horizontal {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}

.enrollment-checkbox {
    display: flex;
}

.enrollment-packet-summary {
    display: flex;
}

.enrollment-summary-cards {
    margin-top: 16px;
}

input:invalid {
    background-color: ivory;
    border: 2px solid variables.$offline-color-text;
}

select:invalid {
    background-color: ivory;
    border: 2px solid variables.$offline-color-text;
}

.enrollment-packet-error {
    background-color: variables.$offline-color;
    border-radius: 4px;
    border: 2px solid variables.$offline-color-text;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hidden {
    display: none;
}

.enrollment-transition-left-2 {
    animation: transition-left-2 1s forwards;
}
.enrollment-transition-right-1 {
    animation: transition-right-1 1s forwards;
}

.enrollment-slide-in-right {
    width: 100%;
    flex-shrink: 1;
    animation: slide-in-right 1s forwards;
}

.enrollment-slide-in-left {
    width: 100%;
    flex-shrink: 1;
    animation: slide-in-left 1s forwards;
}

.enrollment-slide-left-2 {
    animation: slide-left-2 1s forwards;
}

.enrollment-slide-left-3 {
    animation: slide-left-3 1s forwards;
}

.enrollment-slide-right-2 {
    animation: slide-right-2 1s forwards;
}

.enrollment-slide-right-1 {
    animation: slide-right-1 1s forwards;
}

.enrollment-slide-out-right {
    width: 100%;
    flex-shrink: 1;
    animation: slide-out-right 1s forwards;
}

.enrollment-slide-out-left {
    width: 100%;
    flex-shrink: 1;
    animation: slide-out-left 1s forwards;
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid rgb(0, 0, 0, 0.2);
}

.enrollment-subtitle {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.total-to-pay-bold {
    font-weight: bold;
    font-size: 20px;
}

.centered-error-message {
    margin-top: 30px;
    text-align: center;
    font-size: 25px;
}

.enrollment {
    &--client-details {
        margin-top: 15px;
        padding-bottom: 100px;
    }
    &--phone-title {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
    &--phone-icon {
        display: flex;
        align-items: center;
        height: 35px;
    }
}

.btn-font-size-smaller {
    font-size: clamp(0.75rem, 0.8rem, 0.875rem);
}

.enrollment-button {
    display: flex;
    flex-direction: column;
    gap: 20vh;

    &--packages {
        @extend .enrollment-button;
        gap: 30px;
    }

    .splide__slide__row {
        min-height: 140px;
    }
}

.enrollment {
    &--input {
        .enrollment--input-field {
            border: 0px;
            background: none;
            border-bottom: 2px solid variables.$target-list-status-text;
            border-radius: 0px;
            font-size: 32px;
            font-weight: 600;
            line-height: 48px;
            padding: 0px;

            &::placeholder {
                font-size: 20px;
                font-weight: 600;
                line-height: 30px;
                color: variables.$in-progress-modal-bg;
            }
        }

        &-error {
            font-size: 12px;
            text-align: center;
            margin: 0 auto;
            margin-top: 10px;
            max-width: 250px;
            color: variables.$error-red;
        }

        &-phone {
            .enrollment--input-number {
                font-size: 38px;
            }
        }
    }

    &--input-label {
        font-size: 22px;
        font-weight: 600;
        line-height: 36px;
        margin-bottom: 50px;
    }

    &--topBar {
        color: variables.$black;
        font-size: 20px;
        font-weight: 600;
        background: none;
    }

    &--step-title {
        font-size: 22px;
        font-weight: 600;
        line-height: 36px;
        margin-bottom: 20px;
    }

    &--top-header {
        background: none;
    }
}

.owner-selection {
    &--parent {
        display: flex;
        gap: 18px;
        justify-content: center;
    }

    &--card {
        width: 100%;
        max-width: 100px;
        height: 100px;
        border-radius: 10px;
        background-color: variables.$white;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &--text {
        font-weight: 600;
        line-height: 21px;
        font-size: 14px;
    }
}

.other-owner {
    &--card {
        &-parent {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
        width: 100%;
        max-width: 80px;
        height: 60px;
        background-color: variables.$white;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &--text {
        font-weight: 600;
        line-height: 21px;
        font-size: 14px;
    }

    &--cards {
        display: flex;
        gap: 14px;
        justify-content: center;
        margin-bottom: 9px;
    }

    &--check {
        width: 16px;
        height: 16px;
        background: variables.$very-dark-green;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        &-card {
            display: flex;
            align-items: center;
            background: rgba(22, 163, 74, 0.5);
            width: 80px;
            justify-content: space-around;
            border-radius: 10px;
            padding: 5px;
            margin-bottom: 30px;

            .other-owner--text {
                color: variables.$white;
            }
        }
    }
}

.selection {
    &--card-selected {
        border: 2px solid variables.$primary-color;
        background-color: rgba(22, 163, 74, 0.2);
    }
}

.number-owner {
    &--header {
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        margin: 40px 0px;
        text-align: left;
        width: 200px;
    }

    &--button {
        padding: 35px 0px;
        margin-top: 40px;
        font-size: 20px;
    }
}

.client-details {
    &--container {
        margin: 21px 0 0;
    }
    &--base {
        margin-top: 46px;
    }

    &--bottom-button {
        padding: 35px 0px;
        font-size: clamp(1rem, 2vw + 1rem, 1.25rem);
        margin-bottom: 30px;
    }

    &--modal {
        background: variables.$modal-background-color;
    }

    &--success-modal-header {
        margin-bottom: 55px;
    }

    &--success-modal-title {
        text-align: center;
        font-size: 24px;
        font-weight: 600;
    }

    &--success-modal-icon {
        display: flex;
        justify-content: center;
        margin-bottom: 47px;
    }

    &--success-modal-message {
        width: 200px;
        margin: 0 auto;
        font-weight: 600;
        line-height: 18px;
        font-size: 13px;
    }

    &--success-modal-text {
        display: flex;
        align-items: center;
        padding: 20px;
    }
}

.adjusted-margin {
    margin-bottom: 11px;
}

.product {
    &--selection {
        &-title {
            font-size: 16px;
            font-weight: 600;
            line-height: 36px;
            text-align: center;
        }
        &-header {
            margin: 30px 0px;
        }
    }
}

.package-selection-footer {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
}

.package-selection-pay-btn {
    width: 45%;
}

.other-packet-btn {
    background-color: white;
    width: 45%;
    border: 1px solid variables.$primary-color;
    color: black;
}

// enrollment base styles

.enrollment-base {
    &--flow-client-registration {
        @include flow-styles(variables.$maroon);
    }
    &--flow-client-modification {
        @include flow-styles(variables.$brown);
    }

    &--container {
        max-width: 90%;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        height: 90vh;
    }
    &--no-packages-btn {
        width: 70%;
        margin: 30px auto;

        button {
            background-color: variables.$maroon;
        }
    }
    &--step-content {
        margin-block: 10px;
        display: flex;
        flex: 1;
        flex-direction: column;
    }
    &--continue-btn {
        margin-top: auto;
        margin-bottom: 10%;
    }
}

.age-form-container {
    display: flex;
    width: 200px;
    height: 70px;
    padding: 0px 10px 0px 20px;
    justify-content: space-between;
    flex-shrink: 0;
    border-radius: 10px;
    background: variables.$in-progress-modal-bg;
    align-items: center;
    margin: 10vh auto;
}

.age-form-container-placement {
    margin-bottom: 0;
    border-radius: 10px 10px 0 0;
}

.age-form-container-selected {
    border: 1px solid variables.$brown;
    background: variables.$white;
}

.age-item-container {
    display: flex;
    height: 250px;
    width: 200px;
    padding: 10px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 0px 0px 10px 10px;
    border: 1px solid variables.$in-progress-modal-bg;
    background: variables.$white;
    margin: 0 auto;
    overflow-y: scroll;
}

.age-item {
    font-size: 40px;
    font-weight: 600;
    line-height: 100%;
    width: 90%;
    text-align: center;
}

.gender-icon-container {
    display: flex;
    width: 100%;
    gap: 52px;
    justify-content: center;
    margin: 15vh auto;
}

.gender-icon {
    background: variables.$in-progress-modal-bg;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gender-icon-img {
    border-radius: 50%;
}

.gender-icon-img-selected {
    @extend .gender-icon;
    border: 4px solid variables.$brown;
    box-shadow: 1px 5px 15px 0px variables.$not-interested-interaction-btn;
    background-color: variables.$white;
}

.gender-selected-text {
    color: variables.$brown;
}

.form-title {
    display: flex;
    align-items: center;
}

.group-role-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 16px;
}

.group-role {
    display: flex;
    gap: 16px;
    &--icon-text {
        display: flex;
        width: 135px;
        height: 140px;
        padding: 4px 10px;
        justify-content: center;
        align-items: center;
        gap: 5px;
        flex-shrink: 0;
        flex-direction: column;
        border-radius: 5px;
        background: variables.$group-role-text-color;
    }
    &--icon-text p {
        text-align: center;
    }
    &--selected {
        @extend .group-role--icon-text;
        border-radius: 5px;
        border: 2px solid variables.$brown;
        background: variables.$group-role-selected;
        box-shadow: 1px 4px 250px 0px variables.$group-role-box-shadow;
    }
}

.no-group-role {
    width: 94%;
    margin: 0 auto;
}
