:export {
  yellowProgressBar: #ebcf3c;
  blueProgressBar: #2C70E3;
  trainingBgColor: #fed673;
  white: #ffffff;
  bodyBgColor: #f6f6f6;
  primaryColor: #16a34a;
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0) scaleY(1) scaleX(1);
    transform: translate(-50%, 0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-36px);
    transform: translateY(-36px);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom-up
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom-up {
  0% {
    -webkit-transform: translate(0%, 1000px);
    transform: translate(0%, 1000px);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(0%, 0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
:root {
  --gap-ratio: 0.5;
  --char-w: 0.95ch;
  --gap: calc(var(--gap-ratio) * var(--char-w));
  --n-char: 10;
  --in-w: calc(var(--n-char) * (var(--char-w) + var(--gap)));
}

.filter-select-parent {
  position: relative;
}
@media (max-width: 540px) {
  .filter-select-parent {
    min-width: 43px;
  }
}

.filter-select {
  padding: 7px 44px;
  border-radius: 4px;
  border: solid 1px #16a34a;
  background-color: #ffffff;
  width: 100%;
  height: 40px;
}
@media (max-width: 540px) {
  .filter-select {
    padding: 6.5px 20px;
    background: none;
    background-color: #ffffff;
    height: 36px;
  }
}

.filter-checkbox {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  accent-color: #16a34a;
}

.filter-parent {
  position: relative;
}

.filter-option {
  background-color: #ffffff;
  box-shadow: 1px 4px 12px 0 rgba(102, 92, 92, 0.15);
  border-radius: 8px;
  position: absolute;
  min-width: 200px;
  margin-top: 8px;
  width: 100%;
  z-index: 9;
}
.filter-option--child {
  padding: 12px;
}
.filter-option--child label {
  font-size: 14px;
  font-weight: 500;
  color: #1e1e19;
  display: flex;
  align-items: center;
}
.filter-option--container {
  height: 200px;
  overflow: scroll;
}

.filter-select-icon {
  position: absolute;
  display: flex;
  margin-left: 16px;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 540px) {
  .filter-select-icon {
    margin-left: 0px;
    top: 0px;
    transform: none;
    left: 0px;
    justify-content: center;
    align-items: center;
    right: 0px;
    bottom: 0px;
  }
}

.select-option {
  font-size: 16px;
  font-weight: 500;
  color: #1e1e19;
}

.filter-button {
  height: 37px;
  border-radius: 6px;
}
.filter-button--parent {
  width: 80%;
  margin: 15px auto;
}

