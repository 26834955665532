:export {
  yellowProgressBar: #ebcf3c;
  blueProgressBar: #2C70E3;
  trainingBgColor: #fed673;
  white: #ffffff;
  bodyBgColor: #f6f6f6;
  primaryColor: #16a34a;
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0) scaleY(1) scaleX(1);
    transform: translate(-50%, 0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-36px);
    transform: translateY(-36px);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom-up
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom-up {
  0% {
    -webkit-transform: translate(0%, 1000px);
    transform: translate(0%, 1000px);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(0%, 0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
:root {
  --gap-ratio: 0.5;
  --char-w: 0.95ch;
  --gap: calc(var(--gap-ratio) * var(--char-w));
  --n-char: 10;
  --in-w: calc(var(--n-char) * (var(--char-w) + var(--gap)));
}

.client-search--main-parent {
  margin-top: 12px;
}
.client-search--form-parent {
  margin-top: 20px;
}
.client-search--or-parent {
  margin: 30px 0px;
}
.client-search--or-divider {
  display: flex;
  flex-direction: row;
}
.client-search--or-divider:before, .client-search--or-divider:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #979797;
  margin: auto;
}
.client-search--or-divider:before {
  margin-right: 10px;
}
.client-search--or-divider:after {
  margin-left: 10px;
}
.client-search--result-containter {
  padding-bottom: 50px;
}

.card-search--parent {
  display: flex;
  gap: 20px;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 10px 8px;
  margin-bottom: 10px;
  cursor: pointer;
}
.card-search--parent:last-child {
  margin-bottom: 0px;
}
.card-search--page-title {
  margin: 12px 0px;
}
.card-search--title {
  font-family: "Work Sans";
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
}
.card-search--full-name {
  flex: 1;
  font-size: 16px;
  color: #1e1e19;
  font-weight: 600;
}
.card-search--other-content {
  flex: 1;
  font-size: 14px;
  color: #636363;
}
.card-search--add-new-client {
  margin-top: 40px;
  margin-bottom: 120px;
}

.add-new-client-link {
  text-decoration: underline;
  color: #16a34a;
  text-align: center;
  cursor: pointer;
}

.next-search-button {
  margin: 0 auto;
  margin-top: 48px;
}

.no-result {
  text-align: center;
  margin-top: 40px;
  font-size: 16px;
  color: #e85b5b;
}

