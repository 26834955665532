:export {
  yellowProgressBar: #ebcf3c;
  blueProgressBar: #2C70E3;
  trainingBgColor: #fed673;
  white: #ffffff;
  bodyBgColor: #f6f6f6;
  primaryColor: #16a34a;
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    transform: translate(0%, 1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0) scaleY(1) scaleX(1);
    transform: translate(-50%, 0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-36px);
    transform: translateY(-36px);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
  }
}
/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom-up
 * ----------------------------------------
 */
@keyframes slide-in-blurred-bottom-up {
  0% {
    -webkit-transform: translate(0%, 1000px);
    transform: translate(0%, 1000px);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(0%, 0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
:root {
  --gap-ratio: 0.5;
  --char-w: 0.95ch;
  --gap: calc(var(--gap-ratio) * var(--char-w));
  --n-char: 10;
  --in-w: calc(var(--n-char) * (var(--char-w) + var(--gap)));
}

.menu-parent, .menu-parent--arrange {
  display: flex;
  width: 100%;
  box-shadow: 0 -2px 5px 0 rgba(84, 84, 84, 0.13);
  background-color: #ffffff;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
}
.menu-parent--arrange {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 800px) {
  .menu-parent, .menu-parent--arrange {
    position: fixed;
    bottom: 0px;
    z-index: 999;
  }
}

.desktop-menu-parent {
  padding: 16px 40px;
  gap: 50px;
  border-bottom: 1px solid rgba(107, 107, 107, 0.25);
  box-shadow: none;
}
.desktop-menu-parent .desktop-menu-children {
  display: flex;
  justify-content: space-evenly;
  gap: 45px;
}
@media (max-width: 1160px) {
  .desktop-menu-parent .desktop-menu-children {
    gap: 15px;
  }
}

.menu-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  font-family: "Work Sans", sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #636363;
}
.menu-child--link, .menu-child--link--disable {
  display: flex;
  text-decoration: none;
  justify-content: center;
}
.menu-child--link:visited, .menu-child--link--disable:visited {
  color: #636363;
}
.menu-child--link--disable {
  display: flex;
  justify-content: center;
}
.menu-child:first-child {
  margin-left: 0px;
}
.menu-child:hover {
  color: #16a34a;
  transition: all 0.5s;
}
.menu-child:hover svg path {
  fill: #16a34a;
  transition: all 0.5s;
}
.menu-child:hover svg rect {
  fill: #16a34a;
  transition: all 0.5s;
}
@media (max-width: 540px) {
  .menu-child:hover {
    color: #636363;
  }
  .menu-child:hover svg path {
    fill: #636363;
    transition: all 0.5s;
  }
  .menu-child:hover svg rect {
    fill: #636363;
    transition: all 0.5s;
  }
}
.menu-child.menu-child--active {
  color: #16a34a;
  font-weight: 600;
  transition: all 0.5s;
}
.menu-child.menu-child--active svg rect {
  fill: #16a34a;
}
.menu-child.menu-child--active svg path {
  fill: #16a34a;
}
.menu-child--link, .menu-child--link--disable {
  position: relative;
}
.menu-child--disable {
  opacity: 0.3;
}

.desktop-menu-child {
  flex-direction: row;
  font-size: 16px;
  margin-left: 0px;
}
.desktop-menu-child .desktop-menu-icon {
  display: flex;
  width: 20px;
  margin-right: 8px;
}
.desktop-menu-child.menu-child--active {
  position: relative;
  z-index: 1;
}
.desktop-menu-child.menu-child--active:before {
  content: "";
  background-color: #f1fdf5;
  padding: 0px 8px;
  border-radius: 4px;
  position: absolute;
  left: -8px;
  right: -8px;
  top: 0px;
  bottom: 0px;
  z-index: -1;
}

.menu-plus {
  align-items: center;
  background: #16a34a;
  border-radius: 100%;
  display: flex;
  height: 46px;
  justify-content: center;
  width: 46px;
}

.menu-btn-logo {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-logo-link {
  display: flex;
}
.menu-logo-link .menu-logo {
  width: 34px;
}

.menu-logout {
  cursor: pointer;
}

.menu-option-parent {
  position: absolute;
  bottom: 60px;
  background: #fff;
  padding: 18px 16px;
  border-radius: 5px;
  right: -3px;
  box-shadow: -5px -7px 14px 3px rgba(164, 164, 148, 0.12);
}

.menu-option--child {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  margin-bottom: 17px;
}
.menu-option--child:last-child {
  margin-bottom: 0px;
}
.menu-option--text {
  font-weight: 500;
  width: max-content;
}

.menu-content {
  text-transform: capitalize;
}

