@use '../../../scss/abstract/variables';

.fc-profile {
    &-header-container {
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
    }
    &-title, &-name {
        height: 30px;
        font-size: 20px;
        font-weight: 500;
        width: fit-content;
        margin: auto;
        line-height: 150%;
        font-style: normal;
        text-align: center;
    }
    &-user-info {
        text-align: center;
        margin-top: 20px;
    }
    &-name {
        color: variables.$default-text;
        font-family: "Work Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        text-transform: uppercase;
    }
    &-id {
        font-size: 16px;
        font-weight: 400;
    }
    &--logout-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: variables.$white;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: absolute;
        right: 5px;
        border: 1px solid variables.$fc-brown-color;
    }
    &-buttons-container {
        margin-top: 30px;
        margin-right: auto;
        margin-left: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 10px;
    }
    &-button {
        display: flex;
        border-radius: 5px;
        width: 75px;
        height: 60px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        gap: 5px;
        background-color: variables.$white;
        color: variables.$fc-brown-color;
    }
}

.fc-ratings {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    margin-top: 30px;
}

.fc-rating-data{
    display: flex;
    width: 72px;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    font-family: "Work Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; 
}

.circle{
    width: 60px;
    height: 60px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    color: variables.$fc-brown-color;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}

.fc-client-card--header{
    color: variables.$fc-brown-color;
    font-family: "Work Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

.fc-client-number--num{
    font-family: "Work Sans";
    font-size: 33px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    color: variables.$fc-brown-color;
}

.zone-info {
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: #FFF;
    & p {
        margin-left: 8px;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        }
}
